// Actions
const LOAD_ALL = "playerDeals/LOAD_ALL";
const LOAD_UNIQUE = "playerDeals/LOAD_UNIQUE";
const UPDATE = "playerDeals/UPDATE";
const CREATE = "playerDeals/CREATE";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.playerDeals.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.playerDeal.id]: action.playerDeal,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.playerDeal.id]: {
            ...state.data[action.playerDeal.id],
            ...action.playerDeal,
          },
        },
      };
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.playerDeal.id]: action.playerDeal,
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const getPlayerDeals = (playerDeals) => ({
  type: LOAD_ALL,
  playerDeals,
});
export const getPlayerDeal = (playerDeal) => ({
  type: LOAD_UNIQUE,
  playerDeal,
});
export const putPlayerDeal = (playerDeal) => ({ type: UPDATE, playerDeal });

export const postPlayerDeal = (playerDeal) => ({ type: CREATE, playerDeal });
