import React, { useState, useEffect, useMemo } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Input,
  Grid,
  Box,
  InputLeftElement,
  Icon,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { createEvent, updateEvent } from "api/events";
import { fetchUsers } from "api/users";
import { fetchEvent } from "api/events";
import { fetchContacts } from "api/contacts";
import { DateInput } from "components/Forms/DateInput.js";
import InputAutofill from "components/Forms/InputAutofill";
import InputCreatefill from "components/Forms/InputCreatefill";
import { fetchArticles } from "api/articles";
import { fetchEvents } from "api/events";
import { fetchTeams } from "api/teams";
import Control from "components/Forms/FormControl";
import Switcher from "components/Forms/Switcher";
import GeocoderInput from "components/Forms/GeocoderInput";
import { UilSearch } from "@iconscout/react-unicons";
import { leagueTypes } from "lib/type";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";
import { createUpload } from "api/upload";
import { ImageControl } from "components/Forms/ImageInput";
import { useDispatch } from "react-redux";

const EventDetails = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const isCreate = location.pathname.includes("create");

  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm();

  const [isEdit, setEdit] = useState(isCreate ? true : false);

  const createdHandler = () => {
    reset();
    reset({
      articlesId: null,
      eventsId: null,
      contactsId: null,
      teamsId: null,
      usersId: null,
      tags: [],
    });
  };
  const updatedHandler = () => setEdit(false);

  const createMutation = useReduxMutation(createEvent, createdHandler);
  const updateMutation = useReduxMutation(updateEvent, updatedHandler);
  const [isLoading, setLoading] = useState(false);

  useSuccessToast(createMutation);
  useSuccessToast(updateMutation);
  useErrorToast(createMutation);
  useErrorToast(updateMutation);

  const createImageUpload = async (data) => {
    if (data.image) {
      try {
        const formData = new FormData();
        formData.append("File", data.image);
        const key = await dispatch(createUpload(formData, "event_cover"));
        data.coverKey = key.files;
        createHandler(data);
      } catch (error) {
        console.log(error);
      }
    } else {
      createHandler(data);
    }
  };

  const createHandler = async (data) => {
    createMutation.submit({
      ...data,
      articlesId: data.articlesId?.map((a) => a.value),
      eventsId: data.eventsId?.map((a) => a.value),
      tags: data.tags?.map((a) => a.value),

      teamsId: data?.teamsId
        ?.map((a) =>
          a?.value?.toString().includes("0-")
            ? teams
                .filter((t) =>
                  t?.data?.league
                    ?.toUpperCase()
                    .includes(a.value?.split("0-")[1])
                )
                .map((a) => a.value)
            : a.value
        )
        .flat(),
      usersId: data?.usersId
        ?.map((a) =>
          a.value === 0 ? users.shift() && users.map((u) => u.value) : a.value
        )
        .flat(),
      contactsId: data.contactsId?.map((a) => a.value),
      externalLinks: [data.externalLinks],
    });
  };

  const updateImageUpload = async (data) => {
    delete data.coverUrl;
    if (data.image && data.image.path) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("File", data.image);
        const key = await dispatch(createUpload(formData, "event_cover"));
        data.coverKey = key.files;
        setLoading(false);
        updateHandler(data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      if (!data.image) data.coverKey = null;
      updateHandler(data);
    }
  };

  const updateHandler = async (data) =>
    updateMutation.submit({
      id: eventId,
      ...data,
      articlesId: data.articlesId?.map((a) => a.value),
      eventsId: data.eventsId?.map((a) => a.value),
      tags: data.tags?.map((a) => a.value),
      teamsId: data?.teamsId
        ?.map((a) =>
          a?.value?.toString().includes("0-")
            ? teams
                .filter((t) =>
                  t?.data?.league
                    ?.toUpperCase()
                    .includes(a.value?.split("0-")[1])
                )
                .map((a) => a.value)
            : a.value
        )
        .flat(),
      usersId: data?.usersId
        ?.map((a) =>
          a.value === 0 ? users.shift() && users.map((u) => u.value) : a.value
        )
        .flat(),
      contactsId: data.contactsId?.map((a) => a.value),
      externalLinks: [data.externalLinks],
    });

  const { event } = eventId
    ? useReduxQuery("Event", () => fetchEvent(eventId), {
        selector: (state) => state.events.data?.[eventId],
        updates: [eventId],
      })
    : [];

  const { users } = useReduxQuery("Users", fetchUsers, {
    selector: (state) => [
      { value: 0, label: "All Users" },
      ...Object.values(state.users.data || [])?.map((u) => ({
        value: u.id,
        label: `${u.firstName} ${u.lastName}`,
      })),
    ],
  });

  const { contacts } = useReduxQuery("Contacts", fetchContacts, {
    selector: (state) =>
      Object.values(state.contacts.data || [])?.map((c) => ({
        value: c.id,
        label: `${c.firstName} ${c.lastName}`,
      })),
  });
  const { articles } = useReduxQuery("Articles", fetchArticles, {
    selector: (state) =>
      Object.values(state.articles.data || [])?.map((a) => ({
        value: a.id,
        label: a.title,
      })),
  });
  const { events } = useReduxQuery("Events", fetchEvents, {
    selector: (state) =>
      Object.values(state.events.data || [])?.map((e) => ({
        value: e.id,
        label: e.title,
      })),
  });
  const { teams } = useReduxQuery("Teams", fetchTeams, {
    selector: (state) =>
      Object.values(state.teams.data || [])?.map((t) => ({
        value: t.id,
        label: `${t.city} ${t.name}`,
        data: t,
      })),
  });

  const teamsOptions = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        label: league,
        options: [
          { value: `0-${league}`, label: `All ${league}`, data: {} },
          ...teams
            ?.filter(
              (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
            )
            .sort((a, b) => a.label.localeCompare(b.label)),
        ],
      })),
    [teams]
  );

  const handleSetAddress = (address) => {
    if (address) {
      setValue(
        "streetAddress",
        `${address?.street_number || ""} ${address?.route}`.trim(),
        { shouldTouch: true }
      );
      setValue("city", address?.locality, { shouldTouch: true });
      setValue("state", address?.administrative_area_level_1, {
        shouldTouch: true,
      });
      setValue("zip", address?.postal_code, { shouldTouch: true });
      setValue("lat", address?.lat, { shouldTouch: true });
      setValue("lon", address?.lng, { shouldTouch: true });
    }
  };

  useEffect(() => {
    if (event) {
      const data = {
        ...event,
        tags: event.tags?.map((t) => ({ value: t, label: t })),
        eventsId: event.subEvents?.map((e) => ({
          value: e.id,
          label: e.title,
        })),
        contactsId: event.contacts?.map((c) => ({
          value: c.id,
          label: `${c.firstName} ${c.lastName}`,
        })),
        articlesId: event.articles?.map((a) => ({
          value: a.id,
          label: a.title,
        })),
        usersId: event.usersAssigned?.map((u) => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
        })),
        teamsId: event.teamsAssigned?.map((t) => ({
          value: t.id,
          label: t.name,
        })),
        startDate: event.startDate?.split("T")[0],
        endDate: event.endDate?.split("T")[0],
      };
      if (event?.coverKey) {
        data.image = {
          name: event?.coverKey?.split("/").shift().split("~").shift(),
          preview: event?.coverUrl,
        };
      }
      reset(data);
    }
  }, [event]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader>
          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Event {eventId ? "Details" : "Creation"}
            </Text>
            <Button
              hidden={isCreate}
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="no-hover"
              onClick={() => setEdit(!isEdit)}
            >
              {isEdit ? "CANCEL" : "EDIT"}
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <form
              onSubmit={handleSubmit(
                isCreate ? createImageUpload : updateImageUpload
              )}
            >
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap={6}
                align="center"
                w="100%"
                justify="center"
                py="1rem"
                my={5}
              >
                <Grid
                  templateColumns={{
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={6}
                  align="center"
                  w="100%"
                  justify="center"
                  py="1rem"
                >
                  <Control
                    errors={errors.title}
                    label="Title"
                    isRequired={true}
                  >
                    <InputWrapper
                      disabled={!isEdit}
                      placeholder="Enter title"
                      type="text"
                      register={register("title", {
                        required: "Title is required",
                      })}
                    />
                  </Control>
                </Grid>
                <Control
                  errors={errors.description}
                  label="Description"
                  isRequired={true}
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter description"
                    type="text"
                    register={register("description", {
                      required: "Description is required",
                    })}
                  />
                </Control>

                <Grid
                  templateColumns={{
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={6}
                  align="center"
                  w="100%"
                  justify="center"
                  py="1rem"
                >
                  <Control
                    errors={errors.startDate}
                    label="Start Date"
                    isRequired={true}
                  >
                    <DateInput
                      disabled={!isEdit}
                      control={control}
                      name={"startDate"}
                    />
                  </Control>
                  <Control
                    errors={errors.endDate}
                    label="End Date"
                    isRequired={false}
                  >
                    <DateInput
                      disabled={!isEdit}
                      control={control}
                      name={"endDate"}
                    />
                  </Control>
                </Grid>
                {isEdit && (
                  <Control label="Search Location">
                    <GeocoderInput
                      disabled={!isEdit}
                      onSelectAddress={handleSetAddress}
                    >
                      <InputLeftElement
                        pointerEvents="none"
                        color="#dee2e6"
                        fontSize="1.2em"
                        height="100%"
                      >
                        <Icon as={UilSearch} boxSize="6" />
                      </InputLeftElement>
                    </GeocoderInput>
                  </Control>
                )}

                <Control
                  errors={errors.streetAddress}
                  label="Street Address"
                  isRequired={false}
                >
                  <InputWrapper
                    disabled={true}
                    placeholder="Enter street address"
                    register={register("streetAddress")}
                  />
                </Control>
                <Grid
                  templateColumns={{
                    sm: "repeat(1, 1fr)",
                    md: "repeat(3, 1fr)",
                  }}
                  gap={6}
                  align="center"
                  w="100%"
                  justify="center"
                  py="1rem"
                >
                  <Control errors={errors.city} label="City">
                    <InputWrapper
                      disabled={true}
                      placeholder="Enter city"
                      register={register("city")}
                    />
                  </Control>
                  <Control
                    errors={errors.state}
                    label="State"
                    isRequired={false}
                  >
                    <InputWrapper
                      disabled={true}
                      placeholder="Enter state"
                      register={register("state")}
                    />
                  </Control>
                  <Control errors={errors.zip} label="Zip">
                    <InputWrapper
                      disabled={true}
                      placeholder="Enter zip"
                      register={register("zip")}
                    />
                  </Control>
                  {/* Lat, Lon */}
                  <Input {...register("lat")} type="hidden" />
                  <Input {...register("lon")} type="hidden" />
                </Grid>
                <Control
                  errors={errors.externalLinks}
                  label="External Link"
                  isRequired={false}
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter external link"
                    type="text"
                    register={register("externalLinks", {
                      pattern: {
                        value: /^(ftp|http|https):\/\/[^ "]+$/,
                        message: "Invalid URL",
                      },
                    })}
                  />
                </Control>
                <Control errors={errors.tags} label="Tags">
                  <InputCreatefill
                    disabled={!isEdit}
                    options={[]}
                    placeholder="Select Tags"
                    register={register("tags")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.eventsId} label="Related Events">
                  <InputAutofill
                    disabled={!isEdit}
                    options={events}
                    placeholder="Select Events"
                    register={register("eventsId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.contactsId} label="Event Contacts">
                  <InputAutofill
                    disabled={!isEdit}
                    options={contacts}
                    placeholder="Select Contact"
                    register={register("contactsId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.articlesId} label="Event Articles">
                  <InputAutofill
                    disabled={!isEdit}
                    options={articles}
                    placeholder="Select Articles"
                    register={register("articlesId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.usersId} label="User Restriction">
                  <InputAutofill
                    disabled={!isEdit}
                    options={users}
                    placeholder="Select Users"
                    register={register("usersId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.teamsId} label="Team Restriction">
                  <InputAutofill
                    disabled={!isEdit}
                    options={teamsOptions}
                    placeholder="Select Teams"
                    register={register("teamsId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control
                  display="flex"
                  alignItems="center"
                  label="Is this event featured?"
                  isInvalid={errors.featured}
                  isRequired={false}
                >
                  <Switcher
                    disabled={!isEdit}
                    control={control}
                    register={register("featured")}
                  />
                </Control>
                <Control errors={errors.image} label="Cover Image">
                  <ImageControl
                    isDisabled={!isEdit}
                    control={control}
                    name={"image"}
                  />
                </Control>
              </Grid>
              <Flex
                justifyContent="center"
                alignItems="center"
                hidden={!isEdit}
              >
                <Button
                  isLoading={
                    createMutation.loading ||
                    updateMutation.loading ||
                    isLoading
                  }
                  type="submit"
                  bg="red.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  maxW={"200px"}
                  _hover={{ bg: "red.200" }}
                  _active={{ bg: "red.400" }}
                >
                  {isCreate ? "Create" : "Update"}
                </Button>
              </Flex>
            </form>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default EventDetails;

const InputWrapper = ({ children: Children, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
      />
    </Flex>
  );
};
