import React, { useMemo, useEffect, Fragment, useState } from "react";
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  useColorModeValue,
  Box,
  Button,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTable, useSortBy, useGlobalFilter } from "react-table";

import { useReduxQuery } from "hooks/useReduxQuery";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {
  CreatedAt,
  Action,
  Title,
  Basic,
} from "components/Tables/TablesTableRow";
import { fetchNotifications, archiveNotification } from "api/notifications";
import { FaArchive, FaEye } from "react-icons/fa";
import { notificationTypes, notificationTabTypes } from "lib/type";

function Notifications({ search }) {
  const [tabIndex, setTabIndex] = useState(0);
  const textColor = useColorModeValue("gray.700", "white");
  const { notifications, loadingNotifications } = useReduxQuery(
    "Notifications",
    fetchNotifications
  );

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <Header textColor={textColor} />
        <Tabs isFitted onChange={(index) => setTabIndex(index)} mt={3}>
          <TabList>
            {Object.values(notificationTabTypes).map((type, idx) => (
              <Tab _focus={{ boxShadow: "none" }} key={idx}>
                {type}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {Object.values(notificationTabTypes).map((type, idx) => (
              <TabPanel key={idx}>
                <CardBody>
                  {loadingNotifications && <Loader />}
                  {!loadingNotifications && (
                    <NotificationTable
                      notifications={notifications}
                      search={search}
                      textColor={textColor}
                      type={type}
                      currentTab={Object.values(notificationTabTypes)[tabIndex]}
                    />
                  )}
                </CardBody>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Card>
    </Flex>
  );
}

export default Notifications;

const Header = ({ textColor }) => {
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const title = "Notifications List";

  return (
    <CardHeader>
      <Flex justify="space-between" align="center" minHeight="60px" w="100%">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title}
        </Text>
        <Button
          bg={bgButton}
          color="white"
          fontSize="xs"
          variant="no-hover"
          as={ReactRouterLink}
          to="/notifications/send"
        >
          SEND NOTIFICATION
        </Button>
      </Flex>
    </CardHeader>
  );
};

const Loader = () => (
  <Flex w="100%" justifyContent="center" py={80}>
    <Spinner thickness="5px" size="xl" />
  </Flex>
);

const TableHeader = ({ headerGroups }) => {
  return (
    <Thead>
      {headerGroups.map((headerGroup, index) => (
        <Tr
          {...headerGroup.getHeaderGroupProps()}
          my=".8rem"
          pl="0px"
          color="gray.400"
          key={index}
        >
          {headerGroup.headers.map((column, idx) => (
            <TableHeaderContent column={column} key={idx} index={idx} />
          ))}
        </Tr>
      ))}
    </Thead>
  );
};

const TableHeaderContent = ({ column, index }) => {
  return (
    <Th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      color="gray.400"
      ps={index === 0 ? "0px" : null}
    >
      <Flex
        alignItems="center"
        justifyContent={column.isNumeric ? "flex-end" : null}
      >
        <Box as="span">{column.render("Header")}</Box>
        <Box pl="4">
          {column.isSorted &&
            (column.isSortedDesc ? (
              <TriangleDownIcon aria-label="sorted descending" />
            ) : (
              <TriangleUpIcon aria-label="sorted ascending" />
            ))}
        </Box>
      </Flex>
    </Th>
  );
};

const NotificationTable = ({
  notifications,
  search,
  textColor,
  currentTab,
}) => {
  const data = useMemo(
    () =>
      Object.values(notifications || []).filter((n) =>
        currentTab === notificationTabTypes.ARCHIVED
          ? n.archived === true
          : currentTab === notificationTabTypes.CONTACT
          ? (n.type === notificationTabTypes.CONTACT ||
              n.type === notificationTypes.PROFESSIONAL_SERVICES ||
              n.type === notificationTypes.LEAGUE_CONTACT) &&
            n.archived === false
          : n.type === currentTab && n.archived === false
      ),
    [notifications, currentTab]
  ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: (notification) => notification.title,
        Cell: ({ value: title }) => <Title title={title} />,
      },
      {
        id: "description",
        Header: "Description",
        accessor: (notification) => notification.description,
        Cell: ({ value: description }) => <Basic text={description} />,
      },
      {
        id: "users",
        Header: "Users",
        accessor: (notification) => notification.users,
        Cell: ({ value: users }) => (
          <Basic text={users?.map((u) => u.lastName)?.join(", ") || "-"} />
        ),
      },
      {
        id: "teams",
        Header: "Teams",
        accessor: (notification) => notification.teams,
        Cell: ({ value: users }) => (
          <Basic text={users?.map((u) => u.name)?.join(", ") || "-"} />
        ),
      },
      {
        id: "type",
        Header: "Type",
        accessor: (notification) => notification.type,
        Cell: ({ value: type }) => <Basic text={type} />,
      },
      {
        id: "createdAt",
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <CreatedAt date={value} />,
      },
      {
        id: "action",
        accessor: (notification) => notification,
        disableSortBy: true,
        Cell: ({ value: { id, title } }) => (
          <Action
            keyObject="notifications"
            id={id}
            title={title}
            remove={() =>
              archiveNotification(
                id,
                currentTab === notificationTypes.ARCHIVED ? false : true
              )
            }
            action={
              currentTab === notificationTypes.ARCHIVED
                ? "Unarchive"
                : "Archive"
            }
            actions={[
              { title: "VIEW", icon: FaEye },
              {
                title:
                  currentTab === notificationTypes.ARCHIVED
                    ? "UNARCHIVE"
                    : "ARCHIVE",
                icon: FaArchive,
                color: "green.500",
              },
            ]}
          />
        ),
      },
    ],
    [currentTab]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {
    setHiddenColumns(
      columns
        .filter((column) =>
          currentTab === notificationTypes.GLOBAL
            ? column.id === "users" || column.id === "teams"
            : false
        )
        .map((column) => column.id)
    );
  }, [columns, currentTab]);

  useEffect(() => {
    setGlobalFilter(search || "");
  }, [search, setGlobalFilter]);

  return (
    <Table variant="simple" color={textColor} {...getTableProps()}>
      <TableHeader headerGroups={headerGroups} />
      <Tbody {...getTableBodyProps()}>
        {rows?.map((row, idx) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={idx}>
              {row?.cells?.map((cell, index) => (
                <Fragment key={index}>{cell.render("Cell")}</Fragment>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
