import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeToken } from "redux/auth";
import { removeUser } from "api/users";

const Logout = () => {
  const dispatch = useDispatch();
  dispatch(removeToken());
  dispatch(removeUser());
  return <Navigate to="/login" />;
};

export default Logout;
