import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLayout from "../layouts/Admin";
import AuthLayout from "../layouts/Auth";
import RequireNotAuth from "./RequireNotAuth";

const Navigation = () => {
  return (
    <Routes>
      <Route exact path="*" element={<AdminLayout />} />
      <Route path="/login" element={<RequireNotAuth />}>
        <Route path="/login/*" index element={<AuthLayout />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
