import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getEvents,
  getEvent,
  postEvent,
  putEvent,
  deleteEvent,
} from "../redux/events";

export const fetchEvents = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/events`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getEvents(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchEvent = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/events/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getEvent(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createEvent = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/events`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postEvent(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateEvent = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/events/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putEvent(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const removeEvent = (id) => async (dispatch, getState) => {
  try {
    await axiosHandler({
      url: `admin/events/${id}`,
      method: "DELETE",
      state: getState(),
    });
    await dispatch(deleteEvent(id));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
