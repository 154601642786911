// Actions
const LOAD_ALL = "breakdowns/LOAD_ALL";
const LOAD_UNIQUE = "breakdowns/LOAD_UNIQUE";
const CREATE = "breakdowns/CREATE";
const UPDATE = "breakdowns/UPDATE";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.breakdowns.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.breakdown.id]: action.breakdown,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.breakdown.id]: {
            ...state.data[action.breakdown.id],
            ...action.breakdown,
          },
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const getBreakdowns = (breakdowns) => ({ type: LOAD_ALL, breakdowns });
export const getBreakdown = (breakdown) => ({ type: LOAD_UNIQUE, breakdown });
export const postBreakdown = (breakdown) => ({ type: CREATE, breakdown });
export const putBreakdown = (breakdown) => ({ type: UPDATE, breakdown });
