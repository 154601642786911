import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Grid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Icon,
  FormControl,
  HStack,
  FormLabel,
  Spinner,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useReduxMutation } from "hooks/useReduxQuery";
import { FaPencilAlt } from "react-icons/fa";
import { createComparablePlayer } from "api/comparablePlayers";
import { updateComparablePlayer } from "api/comparablePlayers";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";
import useSearchPlayer from "hooks/useSearchPlayer";
// import { InputControl, InputWrapper } from "../components";

const UserComparablePlayers = ({ comparablePlayers, marketValueId }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  const [isEdit, setEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    !isOpen && editData && setEditData([]);
  }, [isOpen]);

  return (
    <>
      <Flex
        flex-direction="row"
        justify="space-between"
        align="center"
        minHeight="60px"
        w="100%"
        py={10}
        borderTop="1px solid"
        borderColor={borderColor}
      >
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Comparable Players
        </Text>
        <Button
          bg={bgButton}
          color="white"
          fontSize="xs"
          variant="no-hover"
          onClick={() => {
            setEdit(false);
            onOpen();
          }}
        >
          ADD
        </Button>
      </Flex>
      {comparablePlayers
        ? Object.values(comparablePlayers).map((b, idx) => (
            <Card
              key={idx}
              border="1px solid"
              borderRadius="15px"
              borderColor={borderColor}
              marginBottom={5}
            >
              <Flex
                p={2}
                justify="space-between"
                align="center"
                minHeight="60px"
                w="100%"
              >
                {b.name}
                <Button
                  p="0px"
                  bg="transparent"
                  onClick={() => {
                    setEditData(b);
                    setEdit(true);
                    onOpen();
                  }}
                >
                  <Flex
                    color={textColor}
                    cursor="pointer"
                    align="center"
                    p="12px"
                  >
                    <Icon as={FaPencilAlt} me="4px" />
                    <Text fontSize="sm" fontWeight="semibold">
                      EDIT
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </Card>
          ))
        : null}
      <ComparablePlayerModal
        comparablePlayer={editData}
        isOpen={isOpen}
        onClose={onClose}
        isEdit={isEdit}
        marketValueId={marketValueId}
      />
    </>
  );
};

export default UserComparablePlayers;

const ComparablePlayerModal = ({
  comparablePlayer,
  isEdit,
  isOpen,
  onClose,
  marketValueId,
}) => {
  const createMutation = useReduxMutation(createComparablePlayer);
  const updateMutation = useReduxMutation(updateComparablePlayer);

  useSuccessToast(createMutation);
  useSuccessToast(updateMutation);
  useErrorToast(createMutation);
  useErrorToast(updateMutation);

  const {
    isSearching,
    searchHandler,
    errorSearch,
    results,
  } = useSearchPlayer();
  const playerDetails = results ? results : [];

  const createHandler = async (data) => {
    delete data.id;
    await createMutation.submit({
      ...data,
      marketValueId: marketValueId,
      contractValue: parseInt(`${data.contractValue || 0}`?.replace(/,/g, "")),
      contractAverageValue: parseInt(
        `${data.contractAverageValue || 0}`?.replace(/,/g, "")
      ),
    });
    resetForm();
  };

  const updateHandler = async (data) => {
    await updateMutation.submit({
      ...data,
      marketValueId: marketValueId,
      contractValue: parseInt(`${data.contractValue || 0}`?.replace(/,/g, "")),
      contractAverageValue: parseInt(
        `${data.contractAverageValue || 0}`?.replace(/,/g, "")
      ),
    });
    resetForm();
  };

  const {
    handleSubmit,
    register,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({
      name: null,
      spotracId: null,
      contractLength: null,
      contractValue: null,
      contractAverageValue: null,
      ageSigned: null,
    });
    onClose();
  };

  useEffect(() => {
    if (comparablePlayer)
      reset({
        ...comparablePlayer,
        contractValue: comparablePlayer?.contractValue
          ? parseInt(comparablePlayer?.contractValue).toLocaleString()
          : 0,
        contractAverageValue: comparablePlayer?.contractAverageValue
          ? parseInt(comparablePlayer?.contractAverageValue).toLocaleString()
          : 0,
      });
  }, [comparablePlayer, isOpen]);

  useEffect(() => {
    if (errorSearch)
      setError("spotracId", { type: "custom", message: errorSearch });
    else clearErrors("spotracId");
  }, [errorSearch]);

  useEffect(() => {
    if (playerDetails) {
      reset({
        id: comparablePlayer?.id,
        name: playerDetails?.details?.name?.full_name,
        contractLength: playerDetails?.contracts?.[0]?.details?.years,
        contractValue: playerDetails?.contracts?.[0]?.details?.value,
        contractAverageValue: playerDetails?.contracts?.[0]?.details?.average,
        ageSigned:
          playerDetails?.contracts?.[0]?.details?.start -
          playerDetails?.details?.date_of_birth?.date_of_birth.split("-")[0],
      });
    }
  }, [playerDetails]);

  return (
    <Modal isOpen={isOpen} onClose={resetForm} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEdit ? "Update" : "Create"}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(isEdit ? updateHandler : createHandler)}>
          <ModalBody>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{
                      sm: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                    }}
                    gap={6}
                    align="center"
                    w="100%"
                    justify="center"
                    py="1rem"
                    my={5}
                  >
                    <InputControl
                      errors={errors.age}
                      label="Name"
                      isRequired={true}
                    >
                      <InputWrapper
                        placeholder="Enter Player's Name"
                        type="text"
                        register={register("name", {
                          required: "Name is required",
                        })}
                      />
                    </InputControl>
                    <InputControl
                      errors={errors.spotracId}
                      label="Spotrac ID"
                      isLoading={isSearching}
                    >
                      <InputWrapper
                        placeholder="Enter Spotrac ID"
                        type="number"
                        onChange={({ target: { value } }) =>
                          searchHandler(value)
                        }
                        register={register("spotracId", {
                          validate: () =>
                            errorSearch !== null ? `${errorSearch}` : true,
                        })}
                      />
                    </InputControl>
                    <InputControl errors={errors.year} label="Contract Length">
                      <InputWrapper
                        placeholder="Enter Contract Length"
                        type="text"
                        register={register("contractLength")}
                      />
                    </InputControl>
                    <InputControl
                      errors={errors.contractValue}
                      label="Contract Value"
                    >
                      <InputWrapper
                        placeholder="Enter Contract Value"
                        type="text"
                        register={register("contractValue")}
                      />
                    </InputControl>
                    <InputControl
                      errors={errors.contractAverageValue}
                      label="Average Annual Salary"
                    >
                      <InputWrapper
                        placeholder="Enter Average Value"
                        type="text"
                        register={register("contractAverageValue")}
                      />
                    </InputControl>
                    <InputControl errors={errors.yearlyCash} label="Age Signed">
                      <InputWrapper
                        placeholder="Age Signed"
                        type="text"
                        register={register("ageSigned")}
                      />
                    </InputControl>
                  </Grid>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    hidden={isEdit}
                  ></Flex>
                </Flex>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={resetForm} size="lg">
              Cancel
            </Button>
            <Button
              isLoading={createMutation.loading || updateMutation.loading}
              colorScheme="blue"
              type="submit"
              size="lg"
            >
              {isEdit ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const InputControl = ({
  children,
  label,
  errors,
  hidden,
  isLoading,
  isRequired,
}) => {
  return (
    <FormControl isInvalid={errors} hidden={hidden} isRequired={isRequired}>
      <HStack spacing={1}>
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal" mt={2}>
          {label}
        </FormLabel>
        {isLoading && <Spinner size="sm" color="red.500" thickness="3px" />}
      </HStack>
      {children}
      <FormErrorMessage>{errors && errors.message}</FormErrorMessage>
    </FormControl>
  );
};

const InputWrapper = ({ children: Children, onChange, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
        onChange={(e) => {
          props?.register?.onChange(e);
          onChange && onChange(e);
        }}
      />
    </Flex>
  );
};
