import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getMarketValues,
  getMarketValue,
  putMarketValue,
} from "../redux/marketValues";

export const fetchMarketValues = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: "admin/marketvalue",
      method: "GET",
      state: getState(),
    });
    await dispatch(getMarketValues(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchMarketValue = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/marketvalue/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getMarketValue(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateMarketValue = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/marketvalue/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putMarketValue(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
