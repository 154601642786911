import { loadAdmin, removeAdmin } from "../redux/admin";
import * as Sentry from "@sentry/react";
import { axiosHandler } from "../lib/utils";
import { createToken, removeToken } from "../redux/auth";

export const login = (data) => async (dispatch) => {
  try {
    const json = await axiosHandler({
      url: `admin/auth/login`,
      method: "POST",
      data,
    });
    await dispatch(createToken(json.data?.access_token));
    await dispatch(loadAdmin(json?.data?.user));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const logout = () => async (dispatch) => {
  try {
    await dispatch(removeAdmin());
    await dispatch(removeToken());
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
