import { ProfileIcon } from "components/Icons/Icons";
import RequireNotAuth from "navigation/RequireNotAuth";
import { FiLogOut } from "react-icons/fi";
import { BsCalendarEventFill, BsFillGearFill } from "react-icons/bs";

import Users from "pages/Users";
import { UserCreate } from "pages/UserCreate";
import Events from "pages/Events";
import EventDetails from "pages/EventDetails";
import Articles from "pages/Articles";
import ArticleDetails from "pages/ArticleDetails";
import LeagueContacts from "pages/LeagueContacts";
import Logout from "pages/Logout";
import {
  FaUserGraduate,
  FaUserMd,
  FaUserTie,
  FaBell,
  FaChartLine,
} from "react-icons/fa";
import ProfessionalServices from "pages/ProfessionalServices";
import ProfessionalServicesDetails from "pages/ProfessionalServicesDetails";
import MarketingContacts from "pages/MarketingContacts";
import MarketingContactsDetails from "pages/MarketingContactsDetails";
import LeagueContactDetails from "pages/LeagueContactDetails";
import Notifications from "pages/Notifications";
import NotificationDetails from "pages/NotificationDetails";
import { Flex } from "@chakra-ui/react";
import User from "pages/UserEdit";
import { Settings } from "pages/Settings";

export default [
  {
    path: "/",
    name: "Home",
    icon: <ProfileIcon color="inherit" />,
    component: <RequireNotAuth />,
    hide: true,
    loginRequired: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: <ProfileIcon color="inherit" />,
    component: <Users />,
    isSearch: true,
    loginRequired: true,
  },
  {
    path: "/users/view/:userId",
    name: "User Details",
    icon: <ProfileIcon color="inherit" />,
    component: <User />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Users", "User Details"],
  },
  {
    path: "/users/create",
    name: "Create User",
    icon: <ProfileIcon color="inherit" />,
    component: (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <UserCreate />
      </Flex>
    ),
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Users", "Create New User"],
  },
  {
    path: "/events",
    name: "Events",
    icon: <BsCalendarEventFill />,
    component: <Events />,
    isSearch: true,
    loginRequired: true,
  },
  {
    path: "/events/view/:eventId",
    name: "Event Details",
    icon: <BsCalendarEventFill />,
    component: <EventDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Events", "Event Details"],
  },
  {
    path: "/events/create",
    name: "Create Event",
    icon: <BsCalendarEventFill />,
    component: <EventDetails />,
    hide: true,
    isSearch: true,
    loginRequired: true,
    breadcrumbs: ["Events", "Create New Event"],
  },
  {
    path: "/articles",
    name: "Education",
    icon: <FaUserGraduate />,
    component: <Articles />,
    isSearch: true,
    loginRequired: true,
  },
  {
    path: "/articles/view/:articleId",
    name: "Article Details",
    icon: <FaUserGraduate />,
    component: <ArticleDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Education", "Article Details"],
  },
  {
    path: "/articles/create",
    name: "Create Article",
    icon: <FaUserGraduate />,
    component: <ArticleDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Education", "Create New Article"],
  },
  {
    path: "/league-contacts",
    name: "League Contacts",
    icon: <FaUserTie />,
    component: <LeagueContacts />,
    isSearch: true,
    loginRequired: true,
  },
  {
    path: "/league-contacts/create",
    name: "Create League Contact",
    icon: <FaUserTie />,
    component: <LeagueContactDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["League Contacts", "Create League Contact"],
  },
  {
    path: "/league-contacts/view/:contactId",
    name: "League Contact Details",
    icon: <FaUserTie />,
    component: <LeagueContactDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["League Contacts", "League Contact Details"],
  },
  {
    path: "/professional-services",
    name: "Professional Services",
    icon: <FaUserMd />,
    component: <ProfessionalServices />,
    isSearch: true,
    loginRequired: true,
  },
  {
    path: "/professional-services/view/:contactId",
    name: "Professional Services Details",
    icon: <FaUserMd />,
    component: <ProfessionalServicesDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Professional Services", "Professional Services Details"],
  },
  {
    path: "/professional-services/create",
    name: "Create Professional Services",
    icon: <FaUserMd />,
    component: <ProfessionalServicesDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Professional Services", "Create Professional Services"],
  },
  {
    path: "/marketing-contacts",
    name: "Marketing Contacts",
    icon: <FaChartLine />,
    component: <MarketingContacts />,
    isSearch: true,
    loginRequired: true,
  },
  {
    path: "/marketing-contacts/create",
    name: "Create Marketing Contacts",
    icon: <FaChartLine />,
    component: <MarketingContactsDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Marketing Contacts", "Create Marketing Contacts"],
  },
  {
    path: "/marketing-contacts/view/:contactId",
    name: "Marketing Contacts Details",
    icon: <FaChartLine />,
    component: <MarketingContactsDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Marketing Contacts", "Marketing Contacts Details"],
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: <FaBell />,
    component: <Notifications />,
    isSearch: true,
    loginRequired: true,
  },
  {
    path: "/notifications/view/:notificationId",
    name: "Notifications Details",
    icon: <FaBell />,
    component: <NotificationDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Notifications", "Notifications Details"],
  },
  {
    path: "/notifications/send",
    name: "Send Notification",
    icon: <FaBell />,
    component: <NotificationDetails />,
    hide: true,
    loginRequired: true,
    breadcrumbs: ["Notifications", "Send Notification"],
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <BsFillGearFill />,
    component: <Settings />,
    hide: false,
    loginRequired: true,
  },
  {
    path: "/logout",
    name: "Sign Out",
    icon: <FiLogOut />,
    component: <Logout />,
    loginRequired: true,
  },
];
