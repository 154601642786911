import React, { useState, useEffect, useMemo } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Grid,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { fetchTeams } from "api/teams";
import Control from "components/Forms/FormControl";
import InputAutofill from "components/Forms/InputAutofill";
import { FaPencilAlt } from "react-icons/fa";
import { createBreakdown } from "api/breakdowns";
import { updateBreakdown } from "api/breakdowns";
import { leagueTypes } from "lib/type";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";
import { numberWithCommas } from "lib/utils";
import { InputControl, InputWrapper } from "../components";

const UserContractBreakdown = ({ breakdowns, contractId, userId }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  const [isEdit, setEdit] = useState(false);
  const [editData, setEditData] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { teams } = useReduxQuery("Teams", fetchTeams, {
    selector: (state) =>
      Object.values(state.teams.data || [])?.map((t) => ({
        value: t.id,
        label: `${t.city} ${t.name}`,
        data: t,
      })),
  });

  useEffect(() => {
    editData && !isOpen && setEditData([]);
  }, [isOpen]);

  return (
    <>
      <Flex
        flex-direction="row"
        justify="space-between"
        align="center"
        minHeight="60px"
        w="100%"
        py={10}
        borderTop="1px solid"
        borderColor={borderColor}
      >
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Contract Breakdown
        </Text>
        <Button
          bg={bgButton}
          color="white"
          fontSize="xs"
          variant="no-hover"
          onClick={() => {
            setEdit(false);
            onOpen();
          }}
        >
          ADD
        </Button>
      </Flex>
      {breakdowns
        ? Object.values(breakdowns).map((b, idx) => (
            <Card
              key={idx}
              border="1px solid"
              borderRadius="15px"
              borderColor={borderColor}
              marginBottom={5}
            >
              <Flex
                p={2}
                justify="space-between"
                align="center"
                minHeight="60px"
                w="100%"
              >
                <VStack w="100%" align="start">
                  <Box>{`Year Signed: ${b.year} | Age: ${b.age || "N/A"}`}</Box>
                  <Box>{`Team: ${
                    teams.find((t) => t.value == b?.teamId)?.label
                  } | Base Salary: $${numberWithCommas(b.baseSalary)}`}</Box>
                  <Box>{`Signing Bonus: $${numberWithCommas(
                    b.signingBonus
                  )} | Year Cash: $${numberWithCommas(b.yearlyCash)}`}</Box>
                  <Box>{`Cap Hit: $${numberWithCommas(
                    b.capHit
                  )} | Dead Cap: $${numberWithCommas(b.deadCap)}`}</Box>
                </VStack>
                <Button
                  p="0px"
                  bg="transparent"
                  onClick={() => {
                    setEditData(b);
                    setEdit(true);
                    onOpen();
                  }}
                >
                  <Flex
                    color={textColor}
                    cursor="pointer"
                    align="center"
                    p="12px"
                  >
                    <Icon as={FaPencilAlt} me="4px" />
                    <Text fontSize="sm" fontWeight="semibold">
                      EDIT
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </Card>
          ))
        : null}
      <BreakdownModal
        breakdown={editData}
        isOpen={isOpen}
        onClose={onClose}
        isEdit={isEdit}
        userId={userId}
        contractId={contractId ? contractId : 0}
        teams={teams}
      />
    </>
  );
};

export default UserContractBreakdown;

const BreakdownModal = ({
  breakdown,
  isEdit,
  isOpen,
  onClose,
  userId,
  contractId,
  teams,
}) => {
  const createdHandler = () => {
    reset();
    reset({
      teamId: null,
    });
  };
  const updatedHandler = () => reset();

  const createMutation = useReduxMutation(createBreakdown, createdHandler);
  const updateMutation = useReduxMutation(updateBreakdown, updatedHandler);

  useSuccessToast(createMutation);
  useSuccessToast(updateMutation);
  useErrorToast(createMutation);
  useErrorToast(updateMutation);

  const createHandler = async (data) => {
    delete data.id;

    createMutation.submit({
      ...data,
      teamId: data?.teamId?.value,
      userId: userId,
      contractId: contractId,
      baseSalary: `${data.baseSalary}`?.replace(/,/g, ""),
      signingBonus: `${data.signingBonus}`?.replace(/,/g, ""),
      yearlyCash: `${data.yearlyCash}`?.replace(/,/g, ""),
      capHit: `${data.capHit}`?.replace(/,/g, ""),
      deadCap: `${data.deadCap}`?.replace(/,/g, ""),
    });
    resetForm();
  };

  const updateHandler = async (data) => {
    updateMutation.submit({
      ...data,
      teamId: data?.teamId?.value,
      userId: userId,
      contractId: contractId,
      baseSalary: `${data.baseSalary}`?.replace(/,/g, ""),
      signingBonus: `${data.signingBonus}`?.replace(/,/g, ""),
      yearlyCash: `${data.yearlyCash}`?.replace(/,/g, ""),
      capHit: `${data.capHit}`?.replace(/,/g, ""),
      deadCap: `${data.deadCap}`?.replace(/,/g, ""),
    });
    resetForm();
  };

  const {
    handleSubmit,
    register,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    reset({
      age: null,
      year: null,
      teamId: null,
      baseSalary: null,
      signingBonus: null,
      yearlyCash: null,
      capHit: null,
      deadCap: null,
    });
    onClose();
  };

  const teamsOptions = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        label: league,
        options: teams
          ?.filter(
            (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
          )
          .sort((a, b) => a.label.localeCompare(b.label)),
      })),
    [teams]
  );

  useEffect(() => {
    if (breakdown)
      reset({
        ...breakdown,
        baseSalary: breakdown?.baseSalary
          ? parseInt(breakdown?.baseSalary).toLocaleString()
          : 0,
        signingBonus: breakdown?.signingBonus
          ? parseInt(breakdown?.signingBonus).toLocaleString()
          : 0,
        yearlyCash: breakdown?.yearlyCash
          ? parseInt(breakdown?.yearlyCash).toLocaleString()
          : 0,
        capHit: breakdown?.capHit
          ? parseInt(breakdown?.capHit).toLocaleString()
          : 0,
        deadCap: breakdown?.deadCap
          ? parseInt(breakdown?.deadCap).toLocaleString()
          : 0,
        teamId: {
          value: breakdown?.teamId,
          label: teams.find((t) => t.value == breakdown?.teamId)?.label,
        },
      });
  }, [breakdown, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={resetForm} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEdit ? "Update" : "Create"}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(isEdit ? updateHandler : createHandler)}>
          <ModalBody>
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{
                      sm: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                    }}
                    gap={6}
                    align="center"
                    w="100%"
                    justify="center"
                    py="1rem"
                    my={5}
                  >
                    <InputControl
                      errors={errors.year}
                      label="Year Signed"
                      isRequired={true}
                    >
                      <InputWrapper
                        placeholder="Enter Year Signed"
                        type="text"
                        register={register("year", {
                          required: "Year Signed is required",
                        })}
                      />
                    </InputControl>
                    <InputControl errors={errors.age} label="Age">
                      <InputWrapper
                        placeholder="Enter Player Age"
                        type="text"
                        register={register("age")}
                      />
                    </InputControl>
                    <Control
                      errors={errors.teamId}
                      label="Team"
                      isRequired={true}
                    >
                      <InputAutofill
                        options={teamsOptions}
                        placeholder="Select Team"
                        register={register("teamId")}
                        control={control}
                        isMulti={false}
                      />
                    </Control>
                    <InputControl
                      errors={errors.baseSalary}
                      label="Base Salary"
                      isRequired={true}
                    >
                      <InputWrapper
                        placeholder="Enter Base Salary"
                        type="text"
                        isNumber={true}
                        register={register("baseSalary", {
                          required: "Base Salary is required",
                        })}
                      />
                    </InputControl>
                    <InputControl
                      errors={errors.signingBonus}
                      label="Signing Bonus"
                    >
                      <InputWrapper
                        isNumber={true}
                        placeholder="Enter Signing Bonus"
                        type="text"
                        register={register("signingBonus")}
                      />
                    </InputControl>
                    <InputControl
                      errors={errors.yearlyCash}
                      label="Yearly Cash"
                    >
                      <InputWrapper
                        isNumber={true}
                        placeholder="Yearly Cash"
                        type="text"
                        register={register("yearlyCash")}
                      />
                    </InputControl>
                    <InputControl errors={errors.capHit} label="Cap Hit">
                      <InputWrapper
                        isNumber={true}
                        placeholder="Enter Cap Hit"
                        type="text"
                        register={register("capHit")}
                      />
                    </InputControl>
                    <InputControl errors={errors.deadCap} label="Dead Cap">
                      <InputWrapper
                        isNumber={true}
                        placeholder="Enter Dead Cap"
                        type="text"
                        register={register("deadCap")}
                      />
                    </InputControl>
                  </Grid>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    hidden={isEdit}
                  ></Flex>
                </Flex>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={resetForm} size="lg">
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" size="lg">
              {isEdit ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
