// Actions
const LOAD = "admin/LOAD";
const REMOVE = "admin/REMOVE";

// Reducer
const initialState = { data: null };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        data: action.admin,
      };
    case REMOVE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const loadAdmin = (admin) => ({ type: LOAD, admin });
export const removeAdmin = () => ({ type: REMOVE });
