// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminNavbarLinks from "./AdminNavbarLinks";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarShadowScroll = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none"
  );
  let navbarBgScroll = useColorModeValue(
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let navbarBorderScroll = useColorModeValue(
    "#FFFFFF",
    "rgba(255, 255, 255, 0.31)"
  );
  let navbarFilterScroll = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
  );

  let secondaryMargin = "0px";
  let paddingX = "15px";

  let navbarPosition =
    props.fixed === true && scrolled === true ? "fixed" : "absolute";
  let navbarFilter =
    props.fixed === true && scrolled === true ? navbarFilterScroll : "none";
  let navbarBackdrop = "blur(21px)";
  let navbarShadow =
    props.fixed === true && scrolled === true ? navbarShadowScroll : "none";
  let navbarBg =
    props.fixed === true && scrolled === true ? navbarBgScroll : "none";
  let navbarBorder =
    props.fixed === true && scrolled === true
      ? navbarBorderScroll
      : "transparent";

  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "absolute";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener("scroll", changeNavbar);
  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      borderRadius="16px"
      display="flex"
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      left={document.documentElement.dir === "rtl" ? "30px" : ""}
      right={document.documentElement.dir === "rtl" ? "" : "30px"}
      px={{ sm: paddingX, md: "30px" }}
      ps={{ xl: "12px" }}
      pt="8px"
      top="18px"
      w={{ sm: "calc(100vw - 30px)", xl: "calc(100vw - 75px - 275px)" }}
    >
      <Flex
        w="100%"
        flexDirection={{ sm: "column", md: "row" }}
        alignItems={{ xl: "center" }}
      >
        {" "}
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            {props.breadcrumbs.map(({ path, name }, index) => {
              if (index === props.breadcrumbs.length - 1)
                return (
                  <BreadcrumbItem key={index} color={mainText}>
                    <Text
                      color={mainText}
                      borderRadius="inherit"
                      fontWeight="bold"
                      _hover={{ color: { mainText } }}
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{ boxShadow: "none" }}
                    >
                      {name}
                    </Text>
                  </BreadcrumbItem>
                );
              return (
                <BreadcrumbItem key={index} color={mainText}>
                  <BreadcrumbLink as={Link} to={path} color={mainText}>
                    {name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        </Box>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <AdminNavbarLinks
            isSearch={props.isSearch}
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            onSearch={props.onSearch}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
