import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import { getContracts, getContract, putContract } from "../redux/contracts";

export const fetchContracts = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: "admin/contracts",
      method: "GET",
      state: getState(),
    });
    await dispatch(getContracts(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchContract = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/contracts/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getContract(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateContract = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/contracts/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putContract(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
