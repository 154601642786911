// Actions
const LOAD_ALL = "comparablePlayers/LOAD_ALL";
const LOAD_UNIQUE = "comparablePlayers/LOAD_UNIQUE";
const CREATE = "comparablePlayers/CREATE";
const UPDATE = "comparablePlayers/UPDATE";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.comparablePlayers.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.comparablePlayer.id]: action.comparablePlayer,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.comparablePlayer.id]: {
            ...state.data[action.comparablePlayer.id],
            ...action.comparablePlayer,
          },
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const getComparablePlayers = (comparablePlayers) => ({ type: LOAD_ALL, comparablePlayers });
export const getComparablePlayer = (comparablePlayer) => ({ type: LOAD_UNIQUE, comparablePlayer });
export const postComparablePlayer = (comparablePlayer) => ({ type: CREATE, comparablePlayer });
export const putComparablePlayer = (comparablePlayer) => ({ type: UPDATE, comparablePlayer });
