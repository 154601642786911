import React, { useMemo, useEffect, Fragment } from "react";
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  useColorModeValue,
  Box,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTable, useSortBy, useGlobalFilter } from "react-table";

import { useReduxQuery } from "hooks/useReduxQuery";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import {
  CreatedAt,
  Action,
  Title,
  Basic,
} from "components/Tables/TablesTableRow";
import { fetchArticles } from "api/articles";
import { removeArticle } from "api/articles";
import { truncate } from "lib/utils";

function Articles({ search }) {
  const { articles, loadingArticles } = useReduxQuery(
    "Articles",
    fetchArticles
  );
  const textColor = useColorModeValue("gray.700", "white");

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value }) => <Title title={`${value}`} />,
      },
      {
        Header: "Description",
        accessor: (article) => article,
        Cell: ({ value: { description } }) => (
          <Basic text={truncate(description)} />
        ),
      },
      {
        Header: "Tags",
        accessor: (article) => article,
        Cell: ({ value: { tags } }) => <Basic text={`${tags}`} />,
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <CreatedAt date={value} />,
      },
      {
        id: "action",
        accessor: (article) => article,
        disableSortBy: true,
        Cell: ({ value: { id, title } }) => (
          <Action
            keyObject="articles"
            id={id}
            title={title}
            remove={removeArticle}
          />
        ),
      },
    ],
    []
  );

  const data = useMemo(() => Object.values(articles || []), [articles]).sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  useEffect(() => {
    setGlobalFilter(search || "");
  }, [search, setGlobalFilter]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <Header textColor={textColor} />
        <CardBody>
          {loadingArticles && <Loader />}
          {!loadingArticles && (
            <Table variant="simple" color={textColor} {...getTableProps()}>
              <TableHeader headerGroups={headerGroups} />
              <Tbody {...getTableBodyProps()}>
                {rows.map((row, idx) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={idx}>
                      {row.cells.map((cell, index) => (
                        <Fragment key={index}>{cell.render("Cell")}</Fragment>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Articles;

const Header = ({ textColor }) => {
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const title = "Article List";

  return (
    <CardHeader>
      <Flex justify="space-between" align="center" minHeight="60px" w="100%">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title}
        </Text>
        <Button
          bg={bgButton}
          color="white"
          fontSize="xs"
          variant="no-hover"
          as={ReactRouterLink}
          to="/articles/create"
        >
          ADD NEW ARTICLE
        </Button>
      </Flex>
    </CardHeader>
  );
};

const Loader = () => (
  <Flex w="100%" justifyContent="center" py={80}>
    <Spinner thickness="5px" size="xl" />
  </Flex>
);

const TableHeader = ({ headerGroups }) => {
  return (
    <Thead>
      {headerGroups.map((headerGroup, index) => (
        <Tr
          {...headerGroup.getHeaderGroupProps()}
          my=".8rem"
          pl="0px"
          color="gray.400"
          key={index}
        >
          {headerGroup.headers.map((column, idx) => (
            <TableHeaderContent column={column} key={idx} index={idx} />
          ))}
        </Tr>
      ))}
    </Thead>
  );
};

const TableHeaderContent = ({ column, index }) => {
  return (
    <Th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      color="gray.400"
      ps={index === 0 ? "0px" : null}
    >
      <Flex
        alignItems="center"
        justifyContent={column.isNumeric ? "flex-end" : null}
      >
        <Box as="span">{column.render("Header")}</Box>
        <Box pl="4">
          {column.isSorted &&
            (column.isSortedDesc ? (
              <TriangleDownIcon aria-label="sorted descending" />
            ) : (
              <TriangleUpIcon aria-label="sorted ascending" />
            ))}
        </Box>
      </Flex>
    </Th>
  );
};
