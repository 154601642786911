import React from "react";
import { Select } from "chakra-react-select";
import { Controller } from "react-hook-form";

const InputAutofill = ({ options, placeholder, control, ...props }) => {
  const chakraStyles = {
    input: (provided) => ({
      ...provided,
      h: "60px",
      borderRadius: "15px",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "15px",
      fontSize: "md",
      fontWeight: "semibold",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontWeight: "semibold",
      fontSize: "md",
      p: "2",
    }),
  };

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <Select
          placeholder={placeholder}
          selectedOptionStyle="check"
          options={options || [{ value: "", label: "" }]}
          isMulti={props.isMulti}
          hasStickyGroupHeaders={props.hasStickyGroupHeaders}
          chakraStyles={chakraStyles}
          {...props.register}
          onChange={onChange}
          closeMenuOnSelect={props.closeMenuOnSelect || false}
          value={value}
          isDisabled={props.disabled}
          maxMenuHeight={200}
        />
      )}
      control={control}
      name={props.register.name}
    />
  );
};
export default InputAutofill;
