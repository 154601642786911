// Actions
const LOAD_ALL = "settings/LOAD_ALL";
const LOAD_UNIQUE = "settings/LOAD_UNIQUE";
const UPDATE = "settings/UPDATE";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        data: action.settings.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.setting.id]: {
            ...state.data[action.setting.id],
            ...action.setting,
          },
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const getSettings = (settings) => ({
  type: LOAD_ALL,
  settings,
});
export const getSetting = (setting) => ({
  type: LOAD_UNIQUE,
  setting,
});
export const putSetting = (setting) => ({
  type: UPDATE,
  setting,
});
