import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getUsers,
  getUser,
  postUser,
  putUser,
  deleteUser,
} from "../redux/users";

export const fetchUsers = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/users`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getUsers(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchUser = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/users/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getUser(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createUser = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/users`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postUser(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/users/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putUser(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const removeUser = (id) => async (dispatch, getState) => {
  try {
    await axiosHandler({
      url: `admin/users/${id}`,
      method: "DELETE",
      state: getState(),
    });
    await dispatch(deleteUser(id));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
