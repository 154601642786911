export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.stratic.app/api/v1/"
    : process.env.REACT_APP_ENV === "development"
    ? "https://samapi.helm.design/api/v1/"
    : process.env.REACT_APP_ENV === "local"
    ? "http://localhost:4899/api/v1/"
    : null;

export const SPOTRAC_KEY = "0ad9c4dc803d775377e24edd69f1628d";

export const SPOTRAC_API_URL = "https://www.spotrac.com/api/stratic/";
