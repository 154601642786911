import React, { useState, useEffect } from "react";
import { Text, Flex, useColorModeValue, Button, Grid } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { fetchUser } from "api/users";
import { fetchBreakdowns } from "api/breakdowns";
import { fetchContracts, updateContract } from "api/contract";
import { stripHtml } from "string-strip-html";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";
import UserContractBreakdown from "./breakdowns";
import { InputControl, InputWrapper, TextAreaWrapper } from "../components";

const UserContract = () => {
  const { userId } = useParams();

  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const [isEdit, setEdit] = useState(false);
  const updatedHandler = () => setEdit(false);
  const updateMutation = useReduxMutation(updateContract, updatedHandler);

  useSuccessToast(updateMutation);
  useErrorToast(updateMutation);

  const { user } = useReduxQuery("User", () => fetchUser(userId), {
    selector: (state) => state.users.data?.[userId],
  });
  const { contracts } = useReduxQuery("Contracts", () => fetchContracts(), {
    selector: (state) => state.contracts.data,
  });
  const { breakdowns } = useReduxQuery("Breakdowns", () => fetchBreakdowns(), {
    selector: (state) => state.breakdowns.data,
  });

  const contract = Object.values(contracts || []).filter(
    (c) => c.userId == user.id
  )[0];

  const contractBreakdown = Object.values(breakdowns || []).filter(
    (c) => c.contractId === contract?.id
  );
  const updateHandler = async (data) =>
    updateMutation.submit({
      ...data,
      id: contract?.id,
      nextPaycheck: data.nextPaycheck?.toString().replace(/,/g, ""),
    });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (contract && contract?.notes)
      contract.notes = stripHtml(contract?.notes)?.result;
    reset({
      ...contract,
      nextPaycheck: contract?.nextPaycheck
        ? parseInt(contract?.nextPaycheck).toLocaleString()
        : 0,
    });
  }, [contract]);

  useEffect(() => {
    if (user)
      reset({
        ...user,
      });
  }, [user]);

  return (
    <>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader>
          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Contract
            </Text>
            <Button
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="no-hover"
              onClick={() => setEdit(!isEdit)}
            >
              {isEdit ? "CANCEL" : "EDIT"}
            </Button>
          </Flex>
        </CardHeader>
        <CardBody paddingBottom={10}>
          <Flex direction="column" w="100%">
            <form onSubmit={handleSubmit(updateHandler)}>
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                gap={6}
                align="center"
                w="100%"
                justify="center"
                py="1rem"
                my={5}
              >
                <InputControl errors={errors.startYear} label="Start Year">
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Starting Year"
                    type="text"
                    register={register("startYear")}
                  />
                </InputControl>
                <InputControl errors={errors.endYear} label="End Year">
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter End Year"
                    type="text"
                    register={register("endYear")}
                  />
                </InputControl>
                <InputControl
                  errors={errors.currentAccrued}
                  label="Accrued Games"
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Accrued Games"
                    type="text"
                    register={register("currentAccrued")}
                  />
                </InputControl>
                <InputControl
                  errors={errors.totalAccrued}
                  label="Total Accrued Seasons"
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Total Accrued Seasons"
                    type="text"
                    register={register("totalAccrued")}
                  />
                </InputControl>
                <InputControl
                  errors={errors.currentCredited}
                  label="Credited Games"
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Credited Games"
                    type="text"
                    register={register("currentCredited")}
                  />
                </InputControl>
                <InputControl
                  errors={errors.totalCredited}
                  label="Total Credited Seasons"
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Total Credited Seasons"
                    type="text"
                    register={register("totalCredited")}
                  />
                </InputControl>
                <InputControl errors={errors.position} label="Position">
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Player's Position"
                    type="text"
                    register={register("position")}
                  />
                </InputControl>
                <InputControl errors={errors.nflRank} label="NFL Rank">
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Players overall NFL ranking"
                    type="text"
                    register={register("nflRank")}
                  />
                </InputControl>
                <InputControl
                  errors={errors.positionRank}
                  label="Position Rank"
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Rank for Players Position"
                    type="text"
                    register={register("positionRank")}
                  />
                </InputControl>
                <InputControl
                  errors={errors.nextPaycheck}
                  label="Next Paycheck"
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Next Paycheck Total"
                    type="text"
                    isNumber={true}
                    register={register("nextPaycheck")}
                  />
                </InputControl>
              </Grid>
              <InputControl errors={errors.notes} label="Notes">
                <TextAreaWrapper
                  disabled={!isEdit}
                  placeholder="Contract notes"
                  type="text"
                  register={register("notes")}
                />
              </InputControl>
              <Flex
                justifyContent="center"
                alignItems="center"
                hidden={!isEdit}
              >
                <Button
                  isLoading={updateMutation.loading}
                  type="submit"
                  bg="red.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  maxW={"200px"}
                  _hover={{ bg: "red.200" }}
                  _active={{ bg: "red.400" }}
                >
                  Update
                </Button>
              </Flex>
            </form>
          </Flex>
        </CardBody>
        <UserContractBreakdown
          contractId={contract?.id}
          userId={user.id}
          breakdowns={contractBreakdown}
        />
      </Card>
    </>
  );
};

export default UserContract;
