import React, { useEffect, useMemo } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Input,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { fetchSettings } from "api/settings";
import { updateSetting } from "api/settings";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";

export const Settings = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const updatedHandler = () => reset();

  const { settings } = useReduxQuery("Settings", fetchSettings, {
    selector: (state) => state.settings.data,
  });

  const allSettings = useMemo(
    () =>
      Object.values(settings || []).reduce(
        (a, b) => ((a[b.type] = b.value), a),
        {}
      ),
    [settings]
  );

  const updateSettingsMutation = useReduxMutation(
    updateSetting,
    updatedHandler
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const updateHandler = async (data) => updateSettingsMutation.submit(data);

  useSuccessToast(updateSettingsMutation);
  useErrorToast(updateSettingsMutation);

  useEffect(() => {
    if (settings) reset(allSettings);
  }, [allSettings]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader>
          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Settings
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <form onSubmit={handleSubmit(updateHandler)}>
              <VStack spacing={4}>
                <InputControl
                  errors={errors.phone1}
                  label="Phone Number nº1"
                  isRequired={true}
                >
                  <InputWrapper
                    placeholder="Enter phone number"
                    type="phone"
                    register={register("phone1", {
                      required: "Phone Number is required",
                      pattern: /[0-9]{4}/,
                      maxLength: {
                        value: 11,
                        message:
                          "Phone number should have no more than 11 digits",
                      },
                      minLength: {
                        value: 10,
                        message: "Phone number should have less than 10 digits",
                      },
                    })}
                  />
                </InputControl>
                <InputControl errors={errors.phone2} label="Phone Number nº2">
                  <InputWrapper
                    placeholder="Enter phone number"
                    type="phone"
                    register={register("phone2", {
                      pattern: /[0-9]{4}/,
                      maxLength: {
                        value: 11,
                        message:
                          "Phone number should have no more than 11 digits",
                      },
                      minLength: {
                        value: 10,
                        message: "Phone number should have less than 10 digits",
                      },
                    })}
                  />
                </InputControl>
              </VStack>
              <Flex justifyContent="center" alignItems="center">
                <Button
                  type="submit"
                  bg="red.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  maxW={"200px"}
                  _hover={{ bg: "red.200" }}
                  _active={{ bg: "red.400" }}
                >
                  Update
                </Button>
              </Flex>
            </form>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

const InputControl = ({
  children,
  label,
  errors,
  hidden,
  isLoading,
  isRequired,
}) => {
  return (
    <FormControl isInvalid={errors} hidden={hidden} isRequired={isRequired}>
      <HStack spacing={1}>
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal" mt={2}>
          {label}
        </FormLabel>
        {isLoading && <Spinner size="sm" color="red.500" thickness="3px" />}
      </HStack>
      {children}
      <FormErrorMessage>{errors && errors.message}</FormErrorMessage>
    </FormControl>
  );
};

const InputWrapper = ({ children: Children, onChange, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
        onChange={(e) => {
          props?.register?.onChange(e);
        }}
      />
    </Flex>
  );
};
