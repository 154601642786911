// Actions
const LOAD_ALL = "notifications/LOAD_ALL";
const LOAD_UNIQUE = "notifications/LOAD_UNIQUE";
const CREATE = "notifications/CREATE";
const ARCHIVE = "notifications/ARCHIVE";
const REMOVE_ALL = "notifications/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        data: action.notifications.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.notification.id]: action.notification,
        },
      };
    case ARCHIVE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.notification.id]: {
            ...state.data[action.notification.id],
            ...action.notification,
          },
        },
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const getNotifications = (notifications) => ({
  type: LOAD_ALL,
  notifications,
});
export const getNotification = (notification) => ({
  type: LOAD_UNIQUE,
  notification,
});
export const postNotification = (notification) => ({
  type: CREATE,
  notification,
});
export const archivesNotification = (notification) => ({
  type: ARCHIVE,
  notification,
});
export const deleteNotifications = () => ({ type: REMOVE_ALL });
