import React, { useState, useEffect, useMemo } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Grid,
  Divider,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { fetchUser } from "api/users";
import { fetchMarketValues } from "api/marketValue";
import { updateMarketValue } from "api/marketValue";
import { updateContract } from "api/contract";
import { fetchComparablePlayers } from "api/comparablePlayers";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";
import UserComparablePlayers from "./comparablePlayers";
import { InputControl, InputWrapper } from "../components";

const UserMarketValue = () => {
  const { userId } = useParams();
  const location = useLocation();

  const isCreate = location.pathname.includes("create");

  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const [isMarketEdit, setMarketEdit] = useState(false);
  const [isContractEdit, setContractEdit] = useState(false);

  const updatedContractHandler = () => setContractEdit(false);
  const updatedMarketHandler = () => setMarketEdit(false);

  const updateContractMutation = useReduxMutation(
    updateContract,
    updatedContractHandler
  );
  const updateMarketMutation = useReduxMutation(
    updateMarketValue,
    updatedMarketHandler
  );

  useSuccessToast(updateContractMutation);
  useErrorToast(updateContractMutation);
  useSuccessToast(updateMarketMutation);
  useErrorToast(updateMarketMutation);

  const { user } = useReduxQuery("User", () => fetchUser(userId), {
    selector: (state) => state.users.data?.[userId],
  });

  const { contracts } = useReduxQuery("Contracts", () => fetchContracts(), {
    selector: (state) => state.contracts.data,
  });
  const contract = Object.values(contracts || []).filter(
    (c) => c?.userId == user.id
  )[0];

  const { marketValues } = useReduxQuery("MarketValues", fetchMarketValues, {
    selector: (state) => state.marketValues.data,
  });

  const { comparablePlayers } = useReduxQuery(
    "ComparablePlayers",
    fetchComparablePlayers,
    {
      selector: (state) => state.comparablePlayers.data,
    }
  );

  const marketValue = useMemo(() => {
    return Object.values(marketValues || []).filter(
      (mv) => mv.userId == user.id
    )[0];
  }, [JSON.stringify(marketValues), user.id]);

  const userComparablePlayers = useMemo(() => {
    return (
      Object.values(comparablePlayers || []).filter(
        (cp) => cp.marketValueId == marketValue?.id
      ) || {}
    );
  }, [JSON.stringify(comparablePlayers), marketValue?.id]);

  const contractUpdateHandler = async (data) =>
    updateContractMutation.submit({
      id: userId,
      ...data,
      averageSalary: data.averageSalary?.replace(/,/g, ""),
    });

  const marketUpdateHandler = async (data) => {
    updateMarketMutation.submit({
      id: userId,
      ...data,
      averageAnnualSalary: `${data.averageAnnualSalary || 0}`?.replace(
        /,/g,
        ""
      ),
      fiveYearProjection: `${data.fiveYearProjection || 0}`?.replace(/,/g, ""),
    });
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  useEffect(() => {
    if (marketValue) {
      reset({
        ...marketValue,
        averageAnnualSalary: marketValue?.averageAnnualSalary
          ? parseInt(marketValue?.averageAnnualSalary).toLocaleString()
          : 0,
        fiveYearProjection: marketValue?.fiveYearProjection
          ? parseInt(marketValue?.fiveYearProjection).toLocaleString()
          : 0,
      });
    }
  }, [marketValue]);

  useEffect(() => {
    if (contract) {
      reset1({
        ...contract,
        averageSalary: contract?.averageSalary
          ? parseInt(contract?.averageSalary).toLocaleString()
          : 0,
      });
    }
  }, [contract]);

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader>
        <Flex justify="space-between" align="center" minHeight="60px" w="100%">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Market Value
          </Text>
          <Button
            hidden={isCreate}
            bg={bgButton}
            color="white"
            fontSize="xs"
            variant="no-hover"
            onClick={() => setMarketEdit(!isMarketEdit)}
          >
            {isMarketEdit ? "CANCEL" : "EDIT"}
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <form id="market" onSubmit={handleSubmit(marketUpdateHandler)}>
            <Grid
              templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={6}
              align="center"
              w="100%"
              justify="center"
              paddingBottom={10}
              my={5}
            >
              <InputControl
                errors={errors.averageAnnualSalary}
                label="Average Annual Salary"
              >
                <InputWrapper
                  disabled={!isMarketEdit}
                  placeholder="Enter average annual salary"
                  type="text"
                  isNumber={true}
                  register={register("averageAnnualSalary")}
                />
              </InputControl>
              <InputControl
                errors={errors.fiveYearProjection}
                label="5 Year Salary Projection"
              >
                <InputWrapper
                  isNumber={true}
                  disabled={!isMarketEdit}
                  placeholder="Enter 5 year projected salary"
                  type="text"
                  register={register("fiveYearProjection")}
                />
              </InputControl>
              <InputControl errors={errors.nflRank} label="NFL Rank">
                <InputWrapper
                  disabled={!isMarketEdit}
                  placeholder="Enter Players overall NFL ranking"
                  type="text"
                  register={register("nflRank")}
                />
              </InputControl>
              <InputControl errors={errors.positionRank} label="Position Rank">
                <InputWrapper
                  disabled={!isMarketEdit}
                  placeholder="Enter Rank for Players Position"
                  type="text"
                  register={register("positionRank")}
                />
              </InputControl>
            </Grid>
            <Flex
              justifyContent="center"
              alignItems="center"
              hidden={!isMarketEdit}
            >
              <Button
                isLoading={updateMarketMutation.loading}
                type="submit"
                // form="market"
                bg="red.300"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                maxW={"200px"}
                _hover={{ bg: "red.200" }}
                _active={{ bg: "red.400" }}
              >
                Update
              </Button>
            </Flex>
          </form>

          <Divider />

          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Current Contract
            </Text>
            <Button
              hidden={isCreate}
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="no-hover"
              onClick={() => setContractEdit(!isContractEdit)}
            >
              {isContractEdit ? "CANCEL" : "EDIT"}
            </Button>
          </Flex>

          <form id="contract" onSubmit={handleSubmit1(contractUpdateHandler)}>
            <Grid
              templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={6}
              align="center"
              w="100%"
              justify="center"
              py="1rem"
              my={5}
            >
              <InputControl
                errors={errors1.freeAgentYear}
                label="Signed/Free Agent"
              >
                <InputWrapper
                  disabled={!isContractEdit}
                  placeholder="Enter Year"
                  type="text"
                  register={register1("freeAgentYear")}
                />
              </InputControl>
              <InputControl
                errors={errors1.averageSalary}
                label="Average Annual Salary"
              >
                <InputWrapper
                  disabled={!isContractEdit}
                  placeholder="Enter average annual salary"
                  type="text"
                  register={register1("averageSalary")}
                  isNumber={true}
                />
              </InputControl>
              <InputControl errors={errors1.nflRank} label="NFL Rank">
                <InputWrapper
                  disabled={!isContractEdit}
                  placeholder="Enter Players overall NFL ranking"
                  type="text"
                  register={register1("nflRank")}
                />
              </InputControl>
              <InputControl errors={errors1.positionRank} label="Position Rank">
                <InputWrapper
                  disabled={!isContractEdit}
                  placeholder="Enter Rank for Players Position"
                  type="text"
                  register={register1("positionRank")}
                />
              </InputControl>
            </Grid>
            <Flex
              justifyContent="center"
              alignItems="center"
              hidden={!isContractEdit}
            >
              <Button
                isLoading={updateContractMutation.loading}
                type="submit"
                bg="red.300"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                maxW={"200px"}
                _hover={{ bg: "red.200" }}
                _active={{ bg: "red.400" }}
              >
                Update
              </Button>
            </Flex>
          </form>
          <Divider />
          <UserComparablePlayers
            comparablePlayers={userComparablePlayers}
            marketValueId={marketValue?.id}
          />
        </Flex>
      </CardBody>
    </Card>
  );
};

export default UserMarketValue;
