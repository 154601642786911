import React, { useEffect } from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
import theme from "theme/theme.js";
import Login from "pages/Login";

export default function Pages() {
  useEffect(() => {
    document.body.style.overflow = "unset";
    return function cleanup() {};
  });

  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box w="100%">
        <Box w="100%">
          <Login />
        </Box>
        <Box px="24px" mx="auto" width="1044px" maxW="100%">
          <Footer />
        </Box>
      </Box>
    </ChakraProvider>
  );
}
