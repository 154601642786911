import {
  Avatar,
  Button,
  Flex,
  Td,
  Text,
  useColorModeValue,
  Icon,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import { formatDate } from "lib/utils";
import { useDispatch } from "react-redux";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import {
  Link as ReactRouterLink,
  Navigate,
  useNavigate,
} from "react-router-dom";

export const Name = ({ name, logo, email, profileUrl, team }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const text = useMemo(() => (team ? `${name} - ${team}` : name), [team, name]);

  return (
    <Td minWidth={{ sm: "250px" }} pl="0px">
      <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
        <Avatar
          src={profileUrl ? profileUrl : logo}
          w="50px"
          h="50px"
          borderRadius="12px"
          me="18px"
          objectFit={"cover"}
        />
        <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {text}
          </Text>

          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {email}
          </Text>
        </Flex>
      </Flex>
    </Td>
  );
};

export const Title = ({ title }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Td minWidth={{ sm: "250px" }} pl="0px">
      <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
        <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    </Td>
  );
};

export const Basic = ({ text }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Td pl="0px">
      <Flex
        justifyContent="center"
        align="left"
        py=".8rem"
        minWidth="100%"
        flexWrap="nowrap"
      >
        <Flex direction="column">
          <Text textAlign="end" fontSize="md" color={textColor} minWidth="100%">
            {text}
          </Text>
        </Flex>
      </Flex>
    </Td>
  );
};

export const CreatedAt = ({ date }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Td>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
        {date ? formatDate(date) : "-"}
      </Text>
    </Td>
  );
};

export const Action = ({ keyObject, id, title, remove, actions, action }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("gray.700", "white");
  const navigate = useNavigate();

  const onEdit = () => navigate(`/${keyObject}/view/${id}`);

  return (
    <Td>
      <Flex
        direction={{ sm: "column", md: "row" }}
        align="flex-start"
        p={{ md: "24px" }}
      >
        <Button p="0px" bg="transparent" onClick={onEdit}>
          <Flex color={textColor} cursor="pointer" align="center" p="12px">
            <Icon as={actions?.[0]?.icon || FaEye} me="4px" />
            <Text fontSize="sm" fontWeight="semibold">
              {actions?.[0]?.title || "VIEW"}
            </Text>
          </Flex>
        </Button>
        <Button
          p="0px"
          bg="transparent"
          mb={{ sm: "10px", md: "0px" }}
          me={{ md: "12px" }}
          onClick={onOpen}
        >
          <Flex
            color={actions?.[1]?.color || "red.500"}
            cursor="pointer"
            align="center"
            p="12px"
          >
            <Icon as={actions?.[1]?.icon || FaTrashAlt} me="4px" />
            <Text fontSize="sm" fontWeight="semibold">
              {actions?.[1]?.title || "DELETE"}
            </Text>
          </Flex>
        </Button>
      </Flex>
      <DeleteAlert
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        remove={remove}
        id={id}
        action={action}
      />
    </Td>
  );
};

const DeleteAlert = ({ isOpen, onClose, title, remove, id, action }) => {
  const cancelRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    try {
      setLoading(true);
      await dispatch(remove(id));
      setLoading(false);
      onClose();
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {`${action || "Delete"} "${title}"?`}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={onDelete}
              ml={3}
              isLoading={loading}
              loadingText="Submitting"
            >
              {action || "Delete"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
