// Actions
const LOAD_ALL = "contacts/LOAD_ALL";
const LOAD_UNIQUE = "contacts/LOAD_UNIQUE";
const CREATE = "contacts/CREATE";
const UPDATE = "contacts/UPDATE";
const REMOVE_UNIQUE = "contacts/REMOVE_UNIQUE";
const REMOVE_ALL = "contacts/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.contacts.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.contact.id]: action.contact,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.contact.id]: {
            ...state.data[action.contact.id],
            ...action.contact,
          },
        },
      };
    case REMOVE_UNIQUE:
      return {
        ...state,
        data:
          Object.values(state.data || [])
            .filter((u) => u.id !== action.id)
            .reduce((a, b) => ((a[b?.id] = b), a), {}) || null,
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const getContacts = (contacts) => ({ type: LOAD_ALL, contacts });
export const getContact = (contact) => ({ type: LOAD_UNIQUE, contact });
export const postContact = (contact) => ({ type: CREATE, contact });
export const putContact = (contact) => ({ type: UPDATE, contact });
export const deleteContact = (id) => ({ type: REMOVE_UNIQUE, id });
export const deleteContacts = () => ({ type: REMOVE_ALL });
