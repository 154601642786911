import React from "react";
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from "@chakra-ui/react";
import Details from "./Details";
import MarketValue from "./MarketValue";
import Contract from "./Contract";
import { useParams } from "react-router-dom";
import { fetchUser } from "api/users";
import { useReduxQuery } from "hooks/useReduxQuery";
import { userTypes } from "lib/type";
import PlayerDeal from "./PlayerDeal";

export const User = () => {
  //Get user id from params
  const { userId } = useParams();

  const { user } = useReduxQuery("User", () => fetchUser(userId), {
    selector: (state) => state.users.data?.[userId],
  });

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Tabs>
        <TabList>
          <Tab>User Details</Tab>
          {[userTypes.PLAYER, userTypes.COLLEGE].includes(user?.userType) && [
            <Tab key="contract">Contract Details</Tab>,
            <Tab key="marketValue">Market Value</Tab>,
            <Tab key="dealValue">Player Deals</Tab>,
          ]}
        </TabList>

        <TabPanels>
          <TabPanel>
            <Details />
          </TabPanel>
          {[userTypes.PLAYER, userTypes.COLLEGE].includes(user?.userType) && [
            <TabPanel key="contract">
              <Contract />
            </TabPanel>,
            <TabPanel key="marketValue">
              <MarketValue />
            </TabPanel>,
            <TabPanel key="dealValue">
              <PlayerDeal />
            </TabPanel>,
          ]}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default User;
