import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getPlayerDeals,
  getPlayerDeal,
  putPlayerDeal,
  postPlayerDeal,
} from "../redux/playerDeals";

export const fetchPlayerDeals = (userId) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/player-deals?userId=${userId}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getPlayerDeals(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createPlayerDeal = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/player-deals`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postPlayerDeal(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updatePlayerDeal = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/player-deals/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putPlayerDeal(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
