import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import { getSettings, putSetting } from "../redux/settings";

export const fetchSettings = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/global-settings`,
      method: "GET",
      state: getState(),
    });

    await dispatch(getSettings(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateSetting = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/global-settings`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(getSettings(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
