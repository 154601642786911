import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getArticles,
  getArticle,
  postArticle,
  putArticle,
  deleteArticle,
} from "../redux/articles";

export const fetchArticles = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/articles`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getArticles(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchArticle = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/articles/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getArticle(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createArticle = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/articles`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postArticle(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateArticle = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/articles/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putArticle(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const removeArticle = (id) => async (dispatch, getState) => {
  try {
    await axiosHandler({
      url: `admin/articles/${id}`,
      method: "DELETE",
      state: getState(),
    });
    await dispatch(deleteArticle(id));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
