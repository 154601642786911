import { forwardRef, useMemo } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useController } from "react-hook-form";
import InputMask from "@../../components/Forms/InputMask";
import "react-datepicker/dist/react-datepicker.css";
import { UilCalender } from "@iconscout/react-unicons";

const InputElement = forwardRef(
  ({ onClick, onChange, onBlur, formRef, value, name, disabled }, ref) => {
    return (
      <InputGroup
        borderRadius="15px"
        border="none"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        borderColor={"#dee2e6"}
        alignItems={"center"}
        justifyContent={"center"}
        zIndex={0}
      >
        <InputLeftElement
          pointerEvents="none"
          color="#dee2e6"
          fontSize="1.2em"
          height="100%"
        >
          <Icon as={UilCalender} boxSize="6" />
        </InputLeftElement>
        <Input
          borderRadius="15px"
          alignItems={"center"}
          justifyContent={"center"}
          type="text"
          h="60px"
          autoComplete="off"
          cursor="pointer"
          fontWeight="semibold"
          fontSize="md"
          size="lg"
          placeholder="MM/DD/YYYY"
          mask="99/99/9999"
          as={InputMask}
          value={value}
          disabled={disabled}
          name={name}
          id={name}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          ref={(e) => {
            ref(e);
            formRef(e);
          }}
        />
      </InputGroup>
    );
  }
);

export const DateInput = ({ control, name, disabled }) => {
  const { field } = useController({
    control,
    name,
    rules: {
      validate: {
        isValid: (value) => dayjs(value).isValid() || "Not a valid date",
        isFutureDate: (value) =>
          dayjs(value).isAfter(dayjs().startOf("day")) ||
          "Date must be in the future",
      },
    },
  });

  const selectedDate = useMemo(() => {
    const day = dayjs(field.value);
    return day.isValid() && field.value ? day.toDate() : "";
  }, [field.value]);

  return (
    <DatePicker
      zIndex={0}
      disabled={disabled}
      selected={selectedDate}
      onChange={(d) => field.onChange(dayjs(d).format("MM/DD/YYYY"))}
      showTimeSelect
      timeIntervals={15}
      customInput={
        <InputElement
          zIndex={0}
          name={field.name}
          value={field.value}
          onChange={({ target: { value } }) => field.onChange(value)}
          onBlur={field.onBlur}
          formRef={field.ref}
        />
      }
    />
  );
};

export const TimeInput = ({ control, name, disabled }) => {
  const { field } = useController({
    control,
    name,
    rules: {
      validate: {
        timeFormat: (value) =>
          /^(?:\d{1}|\d{2})\/(?:\d{1}|\d{2})\/(?:\d{4}|\d{2})$/g.test(value) ||
          "Time format is invalid",
        isValid: (value) => dayjs(value).isValid() || "Not a valid time",
      },
    },
  });

  return (
    <DatePicker
      disabled={disabled}
      selected={field.value}
      onChange={(date) => field.onChange(date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="hh:mm aa"
      customInput={
        <TimeInputElement
          name={field.name}
          value={field.value}
          onChange={({ target: { value } }) => field.onChange(value)}
          onBlur={field.onBlur}
          formRef={field.ref}
        />
      }
    />
  );
};

const TimeInputElement = forwardRef(
  ({ onClick, onChange, onBlur, formRef, value, name }, ref) => {
    return (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={UilCalender} boxSize="5" />
        </InputLeftElement>
        <Input
          as={InputMask}
          mask="99:99 aa"
          type="text"
          autoComplete="off"
          placeholder="12:00 AM"
          cursor="pointer"
          value={value}
          name={name}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlur}
          ref={(r) => (ref(r), formRef(r))}
        />
      </InputGroup>
    );
  }
);
