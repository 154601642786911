import { appendParamsToUrl, axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import { SPOTRAC_KEY } from "lib/constants";
import { SPOTRAC_API_URL } from "lib/constants";

export const fetchPlayerData = async (playerId) => {
  try {
    if (!playerId) return;
    const params = { key: SPOTRAC_KEY };
    const json = await axiosHandler({
      url: `player/${playerId}?` + appendParamsToUrl(params),
      customBaseURL: SPOTRAC_API_URL,
    });
    return json;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
