import thunk from "redux-thunk";
import { combineReducers, compose, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import admin from "./admin";
import settings from "./settings";
import articles from "./articles";
import auth from "./auth";
import contacts from "./contacts";
import events from "./events";
import users from "./users";
import teams from "./teams";
import notifications from "./notifications";
import breakdowns from "./breakdowns";
import contracts from "./contracts";
import marketValues from "./marketValues";
import comparablePlayers from "./comparablePlayers";
import playerDeals from "./playerDeals";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  admin,
  settings,
  articles,
  auth,
  contacts,
  events,
  users,
  teams,
  notifications,
  contracts,
  breakdowns,
  marketValues,
  comparablePlayers,
  playerDeals
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, reducers);
const middleware = [thunk];
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
const persistor = persistStore(store);

export { store, persistor };
