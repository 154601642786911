// Actions
const LOAD_ALL = "marketValues/LOAD_ALL";
const LOAD_UNIQUE = "marketValues/LOAD_UNIQUE";
const UPDATE = "marketValues/UPDATE";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.marketValues.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.marketValue.id]: action.marketValue,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.marketValue.id]: {
            ...state.data[action.marketValue.id],
            ...action.marketValue,
          },
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const getMarketValues = (marketValues) => ({
  type: LOAD_ALL,
  marketValues,
});
export const getMarketValue = (marketValue) => ({
  type: LOAD_UNIQUE,
  marketValue,
});
export const putMarketValue = (marketValue) => ({ type: UPDATE, marketValue });
