import React, { useState, useEffect, useMemo } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Input,
  Grid,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { set, useForm } from "react-hook-form";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { createUser } from "api/users";
import useSearchPlayer from "hooks/useSearchPlayer";
import { fetchTeams } from "api/teams";
import { fetchEvents } from "api/events";
import Control from "components/Forms/FormControl";
import InputAutofill from "components/Forms/InputAutofill";
import { playerTypes, collegePlayerTypes } from "lib/type";
import { fetchPlayerData } from "api/spotrac";
import { leagueTypes } from "lib/type";
import { playerStatusTypes } from "lib/type";
import { userTypes } from "lib/type";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";
import { ImageControl } from "components/Forms/ImageInput";
import { useDispatch } from "react-redux";
import { createUpload } from "api/upload";
import { upperFirstLetter } from "lib/utils";

export const UserCreate = () => {
  const dispatch = useDispatch();
  const {
    results,
    isSearching,
    searchHandler,
    errorSearch,
  } = useSearchPlayer();

  const textColor = useColorModeValue("gray.700", "white");

  const createdHandler = () => {
    reset();
    reset({
      teamId: null,
      status: null,
      type: null,
      image: null,
      eventsId: null,
      userType: null,
    });
  };

  const createMutation = useReduxMutation(createUser, createdHandler);
  const [isLoading, setLoading] = useState(false);

  useSuccessToast(createMutation);
  useErrorToast(createMutation);

  const { teams } = useReduxQuery("Teams", fetchTeams, {
    selector: (state) =>
      Object.values(state.teams.data || [])?.map((t) => ({
        value: t.id,
        label: `${t.city} ${t.name}`,
        data: t,
      })),
  });

  const { events } = useReduxQuery("Events", fetchEvents, {
    selector: (state) =>
      Object.values(state.events.data || [])?.map((e) => ({
        value: e.id,
        label: e.title,
      })),
  });

  const {
    handleSubmit,
    register,
    unregister,
    watch,
    reset,
    setError,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const userType = watch("userType")?.value;
  const nextPaycheck = watch("nextPaycheck");
  const [prevPaycheck, setPrevPaycheck] = useState("");
  const [userLabel, setUserLabel] = useState(null);

  const teamsOptions = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        label: league,
        options: teams
          ?.filter(
            (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
          )
          ?.filter((t) => {
            if (userType === userTypes.COLLEGE) {
              return t?.data?.league === leagueTypes.FBS ? true : false;
            } else {
              return t?.data?.league === leagueTypes.FBS ? false : true;
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      })),
    [teams, userType]
  );

  const createImageUpload = async (data) => {
    if (data.image) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("File", data.image);
        const key = await dispatch(createUpload(formData, "user_picture"));
        data.pictureKey = key.files;
        setLoading(false);
        createHandler(data);
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    } else {
      createHandler(data);
    }
  };

  const pTypes = useMemo(() => {
    if (userType === userTypes.PLAYER) return playerTypes;
    if (userType === userTypes.COLLEGE) return collegePlayerTypes;
  }, [userType]);

  useEffect(() => {
    if (results?.details) {
      const t = (teams || []).find((t) =>
        results?.details?.team?.name?.includes(t?.label)
      );
      if (t)
        setValue("teamId", {
          value: t?.data?.id,
          label: `${t?.label}`,
          data: t?.data,
        });
    }
  }, [JSON.stringify(results), teams]);

  const createHandler = async (data) => {
    const submissionData = {
      ...data,
      userType: data.userType?.value || userTypes.PLAYER,
      teamId: data.teamId?.value,
      status: data.status?.value,
      type: data.type?.value || playerTypes.NONE,
      eventsId: data?.eventsId?.map((e) => e.value),
    };

    if (submissionData.nextPaycheck) {
      submissionData.nextPaycheck = submissionData?.nextPaycheck
        .toString()
        .replace(/,/g, "");
    }

    if (submissionData.userType === userTypes.EXECUTIVE) {
      submissionData.executive = {
        scoutType: data?.scoutType,
        nextPaycheck: submissionData?.nextPaycheck,
        yearsWithCurrentTeam: Number(data?.yearsWithCurrentTeam),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (submissionData.userType === userTypes.COACH) {
      submissionData.coach = {
        coachType: data?.coachType,
        nextPaycheck: submissionData?.nextPaycheck,
        yearsWithCurrentTeam: Number(data?.yearsWithCurrentTeam),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (submissionData.userType === userTypes.REPORTER) {
      submissionData.reporter = {
        yearsWithCurrentTeam: Number(data?.yearsWithCurrentTeam),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (submissionData.userType === userTypes.AGENT) {
      submissionData.agent = {
        yearsWithCurrentCompany: Number(data?.yearsWithCurrentCompany),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (data.spotracId !== null) {
      let spoTrackData = await fetchPlayerData(data.spotracId);
      let contractDetails = spoTrackData?.player?.contracts?.[0];
      let playerDetails = spoTrackData?.player?.details;
      let marketValueDetails = spoTrackData?.player?.market_value;

      submissionData.contract = {
        years: contractDetails?.details?.years,
        notes: contractDetails?.details?.notes,
        startYear: contractDetails?.details?.start,
        endYear: contractDetails?.details?.end,
        totalSalary: contractDetails?.details?.value,
        averageSalary: Math.round(
          contractDetails?.details?.value / contractDetails?.details?.years
        ),
        currentAccrued: 0,
        totalAccrued: 0,
        currentCredited: 0,
        totalCredited: 0,
        position: playerDetails?.position[0].name,
        nflRank: marketValueDetails?.saved_as?.projection?.league_average_rank,
        positionRank:
          marketValueDetails?.saved_as?.projection?.position_average_rank,
        freeAgentYear: contractDetails?.details?.free_agent_year,
        breakdown: contractDetails?.breakdown.map((b) => {
          const breakdownYear = parseInt(b.year);
          const birthDate = new Date(
            playerDetails?.date_of_birth?.date_of_birth
          ).getFullYear();
          const age = breakdownYear - birthDate;

          const breakdownObj = {
            age: age,
            teamId: teams.find((t) => b.team_name.includes(t.label))?.value,
            baseSalary: b.cash_base,
            capHit: b.cap_signing,
            deadCap: b.cap_cap,
            signingBonus: b.cash_signing,
            year: b.year,
            yearlyCash: b.cash_total,
          };
          return breakdownObj;
        }),
      };

      submissionData.marketValue = {
        fiveYearProjection: marketValueDetails?.saved_as?.projection?.value,
        averageAnnualSalary: marketValueDetails?.saved_as?.projection?.average,
        nflRank: marketValueDetails?.saved_as?.projection?.league_average_rank,
        positionRank:
          marketValueDetails?.saved_as?.projection?.position_average_rank,
        comparablePlayers: marketValueDetails?.saved_as?.comparablePlayers
          ?.filter((b) => b.player_id != data.spotracId)
          .map((b) => ({
            name: b.player_name,
            spotracId: b.player_id,
            contractLength: b.length,
            contractValue: b.value,
            contractAverageValue: b.average,
            ageSigned: b.player_age_signed,
          })),
      };
    }

    createMutation.submit(submissionData);
  };

  useEffect(() => {
    if (errorSearch)
      setError("spotracId", { type: "custom", message: errorSearch });
    else clearErrors("spotracId");
  }, [errorSearch]);

  //Unregister fields not needed for certain user types --> could make more efficient
  useEffect(() => {
    const unregisterFields = [
      "spotracId",
      "teamId",
      "type",
      "status",
      "eventsId",
      "scoutType",
      "nextPaycheck",
      "yearsWithCurrentTeam",
      "yearsWithCurrentCompany",
      "yearsActive",
      "coachType",
    ];

    if (userType !== userTypes.PLAYER || userType !== userTypes.COLLEGE) {
      unregisterFields.forEach((field) => unregister(field));
    }

    if (userType !== userTypes.EXECUTIVE && userType !== userTypes.COACH) {
      unregisterFields.slice(1, 4).forEach((field) => unregister(field));
    }

    if (userType !== userTypes.REPORTER) {
      unregisterFields
        .slice(1, 3)
        .concat(unregisterFields.slice(6))
        .forEach((field) => unregister(field));
    }

    if (userType !== userTypes.EXECUTIVE) {
      setUserLabel(userType?.toLowerCase());
    } else {
      setUserLabel("scout");
    }

    if (userType !== userTypes.AGENT) {
      unregister("yearsWithCurrentCompany");
    }
  }, [userType]);

  useEffect(() => {
    if (
      nextPaycheck &&
      nextPaycheck !== prevPaycheck &&
      Number(parseInt(nextPaycheck.replace(/,/g, "")))
    ) {
      const formattedPaycheck = parseInt(
        nextPaycheck.replace(/,/g, "")
      ).toLocaleString();

      setPrevPaycheck(formattedPaycheck);

      const formData = watch();
      reset({
        ...formData,
        nextPaycheck: formattedPaycheck,
      });
    }
  }, [nextPaycheck]);

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader>
        <Flex justify="space-between" align="center" minHeight="60px" w="100%">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            User Creation
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <form onSubmit={handleSubmit(createImageUpload)}>
            <Grid
              templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={6}
              align="center"
              w="100%"
              justify="center"
              py="1rem"
              my={5}
            >
              <InputControl
                errors={errors.firstName}
                label="First Name"
                isRequired={true}
              >
                <InputWrapper
                  placeholder="Enter first name"
                  type="text"
                  register={register("firstName", {
                    required: "First Name is required",
                  })}
                />
              </InputControl>
              <InputControl
                errors={errors.lastName}
                label="Last Name"
                isRequired={true}
              >
                <InputWrapper
                  placeholder="Enter last name"
                  type="text"
                  register={register("lastName", {
                    required: "Last Name is required",
                  })}
                />
              </InputControl>
              <InputControl
                errors={errors.email}
                label="Email"
                isRequired={true}
              >
                <InputWrapper
                  placeholder="Enter email"
                  autoComplete="email"
                  type="email"
                  register={register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </InputControl>
              <InputControl
                errors={errors.password}
                label="Password"
                isRequired={true}
              >
                <InputWrapper
                  placeholder="Enter password"
                  type="password"
                  autoComplete="new-password"
                  register={register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Minimum length should be 8",
                    },
                  })}
                />
              </InputControl>
              <Control errors={errors.type} label="User Type" isRequired={true}>
                <InputAutofill
                  options={Object.values(userTypes).map((t) => ({
                    value: t,
                    label: t,
                  }))}
                  placeholder="Select User Type"
                  register={register("userType", {
                    required: "User Type is required",
                  })}
                  control={control}
                  isMulti={false}
                  closeMenuOnSelect={true}
                />
              </Control>
              {[userTypes.PLAYER, userTypes.COLLEGE].includes(userType) && (
                <>
                  <InputControl
                    errors={errors.spotracId}
                    label="Spotrac ID"
                    isLoading={isSearching}
                  >
                    <InputWrapper
                      placeholder="Enter Spotrac ID"
                      type="number"
                      onChange={({ target: { value } }) => searchHandler(value)}
                      register={register("spotracId", {
                        setValueAs: (value) =>
                          value?.trim() === "" ? 0 : parseInt(value, 10),
                        validate: () => {
                          if (
                            userType !== userTypes.PLAYER ||
                            userType !== userTypes.COLLEGE
                          ) {
                            return true;
                          }
                          return errorSearch !== null ? `${errorSearch}` : true;
                        },
                      })}
                    />
                  </InputControl>
                  <Control errors={errors.teamId} label="Team">
                    <InputAutofill
                      options={teamsOptions}
                      placeholder="Select Team"
                      register={register("teamId")}
                      control={control}
                      closeMenuOnSelect={true}
                      isMulti={false}
                    />
                  </Control>
                  <Control errors={errors.type} label="Type">
                    <InputAutofill
                      options={Object.values(pTypes).map((t) => ({
                        value: t,
                        label: t,
                      }))}
                      placeholder="Select Type"
                      register={register("type")}
                      control={control}
                      isMulti={false}
                      closeMenuOnSelect={true}
                    />
                  </Control>
                  <Control
                    errors={errors.status}
                    label="Status"
                    isRequired={true}
                  >
                    <InputAutofill
                      options={Object.values(playerStatusTypes).map((t) => ({
                        value: t,
                        label: t,
                      }))}
                      placeholder="Select Status"
                      register={register("status", {
                        required: "Status is required",
                      })}
                      control={control}
                      isMulti={false}
                      closeMenuOnSelect={true}
                    />
                  </Control>
                  <Control errors={errors.eventsId} label="Events">
                    <InputAutofill
                      options={events}
                      placeholder="Select Events"
                      register={register("eventsId")}
                      control={control}
                      isMulti={true}
                    />
                  </Control>
                </>
              )}
              {userType !== userTypes.PLAYER &&
                userType !== userTypes.COLLEGE &&
                userType !== userTypes.PARENT &&
                userType !== userTypes.AGENT &&
                userType !== userTypes.SPOUSE &&
                userType && [
                  <Control errors={errors.teamId} label="Team" key="team">
                    <InputAutofill
                      options={teamsOptions}
                      placeholder="Select Team"
                      register={register("teamId")}
                      control={control}
                      closeMenuOnSelect={true}
                      isMulti={false}
                    />
                  </Control>,
                  userType !== userTypes.REPORTER && [
                    <InputControl
                      errors={errors.type}
                      label={`${upperFirstLetter(userLabel)} Type`}
                      isRequired={true}
                      key={`${userLabel}Type`}
                    >
                      <InputWrapper
                        placeholder={`Enter ${userLabel} type`}
                        type="text"
                        register={register(`${userLabel}Type`, {
                          required: `${upperFirstLetter(
                            userLabel
                          )} Type is required`,
                        })}
                      />
                    </InputControl>,

                    <InputControl
                      errors={errors.nextPaycheck}
                      label="Next Paycheck"
                      key="nextPaycheck"
                    >
                      <InputWrapper
                        placeholder="Enter next paycheck"
                        type="text"
                        register={register("nextPaycheck")}
                      />
                    </InputControl>,
                  ],
                  ,
                  <InputControl
                    errors={errors.yearsWithCurrentTeam}
                    label="Years with Current Team"
                    key="yearsCurrentTeam"
                  >
                    <InputWrapper
                      placeholder="Enter years with current team"
                      type="number"
                      register={register("yearsWithCurrentTeam")}
                    />
                  </InputControl>,
                  <InputControl
                    errors={errors.yearsActive}
                    label="Years Active"
                    key="yearsActive"
                  >
                    <InputWrapper
                      placeholder="Enter years active"
                      type="number"
                      register={register("yearsActive")}
                    />
                  </InputControl>,
                ]}
              {userType === userTypes.AGENT && [
                <InputControl
                  errors={errors.yearsWithCurrentCompany}
                  label="Years with Current Company"
                  key="yearsCurrentCompany"
                >
                  <InputWrapper
                    placeholder="Enter years with current company"
                    type="number"
                    register={register("yearsWithCurrentCompany")}
                  />
                </InputControl>,
                <InputControl
                  errors={errors.yearsActive}
                  label="Years Active"
                  key="yearsActive"
                >
                  <InputWrapper
                    placeholder="Enter years active"
                    type="number"
                    register={register("yearsActive")}
                  />
                </InputControl>,
              ]}
            </Grid>
            <Control errors={errors.image} label="Profile Image">
              <ImageControl control={control} name={"image"} />
            </Control>
            <Flex justifyContent="center" alignItems="center">
              <Button
                isLoading={createMutation.loading || isLoading}
                type="submit"
                bg="red.300"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                maxW={"200px"}
                _hover={{ bg: "red.200" }}
                _active={{ bg: "red.400" }}
                disabled={createMutation.loading || isSearching}
              >
                Create
              </Button>
            </Flex>
          </form>
        </Flex>
      </CardBody>
    </Card>
  );
};

const InputControl = ({
  children,
  label,
  errors,
  hidden,
  isLoading,
  isRequired,
}) => {
  return (
    <FormControl isInvalid={errors} hidden={hidden} isRequired={isRequired}>
      <HStack spacing={1}>
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal" mt={2}>
          {label}
        </FormLabel>
        {isLoading && <Spinner size="sm" color="red.500" thickness="3px" />}
      </HStack>
      {children}
      <FormErrorMessage>{errors && errors.message}</FormErrorMessage>
    </FormControl>
  );
};

const InputWrapper = ({ children: Children, onChange, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
        onChange={(e) => {
          props?.register?.onChange(e);
          onChange && onChange(e);
        }}
      />
    </Flex>
  );
};
