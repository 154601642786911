import React, { useState, useEffect, useMemo } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Input,
  Grid,
  Box,
  Icon,
  InputLeftElement,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import InputAutofill from "components/Forms/InputAutofill";
import Control from "components/Forms/FormControl";
import { createContact } from "api/contacts";
import { updateContact } from "api/contacts";
import { fetchContact } from "api/contacts";
import { leagueContactTypes, contactTypes } from "lib/type";
import { leagueTypes } from "lib/type";
import GeocoderInput from "components/Forms/GeocoderInput";
import { UilSearch } from "@iconscout/react-unicons";
import { fetchTeams } from "api/teams";
import InputCreatefill from "components/Forms/InputCreatefill";
import { leagueStatusRoles } from "lib/type";
import { leagueServices } from "lib/type";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";

const LeagueContactDetails = () => {
  const { contactId } = useParams();
  const location = useLocation();
  const isCreate = location.pathname.includes("create");

  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const [isEdit, setEdit] = useState(isCreate ? true : false);

  const createdHandler = () => {
    reset();
    reset({
      role: null,
      teamId: null,
      services: [],
    });
  };
  const updatedHandler = () => setEdit(false);

  const createMutation = useReduxMutation(createContact, createdHandler);
  const updateMutation = useReduxMutation(updateContact, updatedHandler);

  useSuccessToast(createMutation);
  useSuccessToast(updateMutation);
  useErrorToast(createMutation);
  useErrorToast(updateMutation);

  const createHandler = async (data) => {
    return createMutation.submit({
      ...data,
      services: data.services?.map((a) => a.value),
      role: data.role?.value?.toString(),
      league: data.league?.value?.toString(),
      type: contactTypes.LEAGUE_CONTACT,
      teamId: data?.teamId?.value,
    });
  };

  const updateHandler = async (data) =>
    updateMutation.submit({
      id: contactId,
      ...data,
      services: data.services?.map((a) => a.value),
      role: data.role?.value?.toString(),
      league: data.league?.value?.toString(),
      type: contactTypes.LEAGUE_CONTACT,
      teamId: data?.teamId?.value,
    });

  const { contact } = contactId
    ? useReduxQuery("Contact", () => fetchContact(contactId), {
        selector: (state) => state.contacts.data?.[contactId],
        updates: [contactId],
      })
    : [];
  const { teams } = useReduxQuery("Teams", fetchTeams, {
    selector: (state) =>
      Object.values(state.teams.data || [])?.map((t) => ({
        value: t.id,
        label: `${t.city} ${t.name}`,
        data: t,
      })),
  });

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const handleSetAddress = (address) => {
    setValue(
      "streetAddress",
      `${address?.street_number || ""} ${address?.route}`.trim(),
      { shouldTouch: true }
    );
    setValue("city", address?.locality, { shouldTouch: true });
    setValue("state", address?.administrative_area_level_1, {
      shouldTouch: true,
    });
    setValue("zip", address?.postal_code, { shouldTouch: true });
    setValue("lat", address?.lat, { shouldTouch: true });
    setValue("lon", address?.lng, { shouldTouch: true });
  };

  const handleSetTeam = (team) => {
    setValue("streetAddress", team?.streetAddress, { shouldTouch: true });
    setValue("city", team?.addressCity, { shouldTouch: true });
    setValue("state", team?.addressState, { shouldTouch: true });
    setValue("zip", team?.addressZip, { shouldTouch: true });
    setValue("lat", team?.lat, { shouldTouch: true });
    setValue("lon", team?.lon, { shouldTouch: true });
  };

  const teamsOptions = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        label: league,
        options: teams
          ?.filter(
            (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
          )
          .sort((a, b) => a.label.localeCompare(b.label)),
      })),
    [teams]
  );

  useEffect(() => {
    if (contact)
      reset({
        ...contact,
        role: { value: contact.role, label: contact.role },
        services: contact.services?.map((t) => ({ value: t, label: t })),
        league: { value: contact.league, label: contact.league },
        teamId: {
          value: contact?.teamId,
          label: teams.find((t) => t.value == contact?.teamId)?.label,
        },
      });
  }, [contact]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader>
          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              League Contact {contactId ? "Details" : "Creation"}
            </Text>
            <Button
              hidden={isCreate}
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="no-hover"
              onClick={() => setEdit(!isEdit)}
            >
              {isEdit ? "CANCEL" : "EDIT"}
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <form
              onSubmit={handleSubmit(isCreate ? createHandler : updateHandler)}
            >
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap={6}
                align="center"
                w="100%"
                justify="center"
                py="1rem"
                my={5}
              >
                <Control
                  errors={errors.firstName}
                  label="First Name"
                  isRequired={true}
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter first name"
                    type="text"
                    register={register("firstName", {
                      required: "First Name is required",
                    })}
                  />
                </Control>
                <Control
                  errors={errors.lastName}
                  label="Last Name"
                  isRequired={true}
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter last name"
                    type="text"
                    register={register("lastName")}
                  />
                </Control>
                <Control errors={errors.role} label="Role">
                  <InputCreatefill
                    disabled={!isEdit}
                    options={Object.values(leagueStatusRoles).map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    isMulti={false}
                    placeholder="Select Role"
                    register={register("role")}
                    control={control}
                  />
                </Control>
                <Control errors={errors.services} label="Services">
                  <InputCreatefill
                    disabled={!isEdit}
                    options={Object.values(leagueServices).map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    isMulti={true}
                    placeholder="Select Services"
                    register={register("services")}
                    control={control}
                  />
                </Control>
                <Control errors={errors.teamId} label="Team">
                  <InputAutofill
                    disabled={!isEdit}
                    options={teamsOptions}
                    placeholder="Select Team"
                    register={{
                      ...register("teamId", {
                        onChange: (e) => handleSetTeam(e?.target?.value?.data),
                      }),
                    }}
                    control={control}
                    isMulti={false}
                    hasStickyGroupHeaders={true}
                    closeMenuOnSelect={true}
                  />
                </Control>
                <Control errors={errors.email} label="Email" isRequired={false}>
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter email"
                    register={register("email", {
                      required: false,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </Control>
                <Control
                  errors={errors.phone}
                  label="Phone Number"
                  isRequired={false}
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter phone number"
                    register={register("phone", {
                      pattern: /[0-9]{4}/,
                      maxLength: {
                        value: 11,
                        message:
                          "Phone number should have no more than 11 digits",
                      },
                      minLength: {
                        value: 10,
                        message: "Phone number should have less than 10 digits",
                      },
                    })}
                  />
                </Control>
                {isEdit && (
                  <Control label="Search Location">
                    <GeocoderInput
                      disabled={!isEdit}
                      onSelectAddress={handleSetAddress}
                    >
                      <InputLeftElement
                        pointerEvents="none"
                        color="#dee2e6"
                        fontSize="1.2em"
                        height="100%"
                      >
                        <Icon as={UilSearch} boxSize="6" />
                      </InputLeftElement>
                    </GeocoderInput>
                  </Control>
                )}
                <Control
                  errors={errors.streetAddress}
                  label="Street Address"
                  isRequired={true}
                >
                  <InputWrapper
                    disabled={true}
                    placeholder="Enter street address"
                    register={register("streetAddress", {
                      required: "Street Address is required",
                    })}
                  />
                </Control>
                <Grid
                  templateColumns={{
                    sm: "repeat(1, 1fr)",
                    md: "repeat(3, 1fr)",
                  }}
                  gap={6}
                  align="center"
                  w="100%"
                  justify="center"
                  pb="1rem"
                >
                  <Control errors={errors.city} label="City" isRequired={false}>
                    <InputWrapper
                      disabled={true}
                      placeholder="Enter city"
                      register={register("city")}
                    />
                  </Control>
                  <Control
                    errors={errors.state}
                    label="State"
                    isRequired={true}
                  >
                    <InputWrapper
                      disabled={true}
                      placeholder="Enter state"
                      register={register("state", {
                        required: "State is required",
                      })}
                    />
                  </Control>
                  <Control errors={errors.zip} label="Zip" isRequired={false}>
                    <InputWrapper
                      disabled={true}
                      placeholder="Enter zip"
                      register={register("zip")}
                    />
                  </Control>
                  <Input {...register("lat")} type="hidden" />
                  <Input {...register("lon")} type="hidden" />
                </Grid>
              </Grid>
              <Flex
                justifyContent="center"
                alignItems="center"
                hidden={!isEdit}
              >
                <Button
                  isLoading={createMutation.loading || updateMutation.loading}
                  type="submit"
                  bg="red.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  maxW={"200px"}
                  _hover={{ bg: "red.200" }}
                  _active={{ bg: "red.400" }}
                >
                  {isCreate ? "Create" : "Update"}
                </Button>
              </Flex>
            </form>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default LeagueContactDetails;

const InputWrapper = ({ children: Children, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
      />
    </Flex>
  );
};
