import React from "react";
import {
  Flex,
  useColorModeValue,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Spinner,
  Textarea,
} from "@chakra-ui/react";

export const InputControl = ({
  children,
  label,
  errors,
  hidden,
  isLoading,
  isRequired,
}) => {
  return (
    <FormControl isInvalid={errors} hidden={hidden} isRequired={isRequired}>
      <HStack spacing={1}>
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal" mt={2}>
          {label}
        </FormLabel>
        {isLoading && <Spinner size="sm" color="red.500" thickness="3px" />}
      </HStack>
      {children}
      <FormErrorMessage>{errors && errors.message}</FormErrorMessage>
    </FormControl>
  );
};

export const InputWrapper = ({
  children: Children,
  isNumber = false,
  ...props
}) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  const onChangeNumber = (e) => {
    const t = parseInt(e.target.value?.replace(/,/g, ""))
      .toLocaleString()
      ?.toString();
    if (t !== "NaN") e.target.value = t?.toString();
    else e.target.value = e.target.value?.replace(/,/g, "");
  };

  const onChange = isNumber ? onChangeNumber : props.register.onChange;

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
        onChange={onChange}
      />
    </Flex>
  );
};

export const TextAreaWrapper = ({ children: Children, onChange, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Textarea
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        fontSize="md"
        fontWeight="semibold"
        {...props.register}
        onChange={(e) => {
          props?.register?.onChange(e);
        }}
      />
    </Flex>
  );
};
