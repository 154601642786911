import { forwardRef, useState, useEffect } from "react";
import ReactInputMask from "react-input-mask";

// squashes console warning about using useLayoutEffect on the server
// https://github.com/sanniassin/react-input-mask/issues/188#issuecomment-542193189
const InputMask = forwardRef((props, ref) => {
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) return null;

  return <ReactInputMask ref={ref} {...props} />;
});

export default InputMask;
