import { appendParamsToUrl, axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getContacts,
  getContact,
  postContact,
  putContact,
  deleteContact,
} from "../redux/contacts";

export const fetchContacts = (params = null) => async (dispatch, getState) => {
  try {
    const url = params
      ? "admin/contacts?" + appendParamsToUrl(params)
      : "admin/contacts";
    const json = await axiosHandler({
      url,
      method: "GET",
      state: getState(),
    });
    await dispatch(getContacts(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchContact = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/contacts/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getContact(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createContact = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/contacts`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postContact(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateContact = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/contacts/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putContact(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const removeContact = (id) => async (dispatch, getState) => {
  try {
    await axiosHandler({
      url: `admin/contacts/${id}`,
      method: "DELETE",
      state: getState(),
    });
    await dispatch(deleteContact(id));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
