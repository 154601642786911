import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const useSentry = () => {
  useEffect(() => {
    Sentry.init({
      dsn: "https://7c70f70f79bf4a63924cd8b77155380b@o427721.ingest.sentry.io/6366985",
      integrations: [new BrowserTracing()],
      tracesSampleRate: process.env.NODE_ENV ? 0.01 : 0.02,
      environment: process.env.NODE_ENV ? "development" : "production",
    });
  }, []);
};

export default useSentry;
