import { Flex } from "@chakra-ui/react";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React from "react";
import routes from "routes.js";
import { SearchBar } from "./SearchBar/SearchBar";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, onSearch, ...rest } =
    props;

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {props.isSearch && <SearchBar onSearch={onSearch} />}
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes.filter((r) => !r.hide)}
        {...rest}
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
