import React, { forwardRef, useCallback, useMemo } from "react";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/layout";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@chakra-ui/button";
import { UilImageUpload, UilTimes } from "@iconscout/react-unicons";
import Icon from "@chakra-ui/icon";
import { Controller } from "react-hook-form";

export const AttachmentInput = forwardRef(
  ({ onChange, value, isDisabled }, ref) => {
    const onDrop = useCallback((acceptedFiles) => {
      const file = acceptedFiles;
      onChange(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
          ".pdf",
          ".docx",
          ".doc",
        ],
      },
      multiple: true,
      disabled: isDisabled,
    });

    return (
      <Box>
        {value ? (
          <FileInput
            isDisabled={isDisabled}
            isDragActive={isDragActive}
            value={value}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            onChange={onChange}
          />
        ) : (
          <Box {...getRootProps()}>
            <Input {...getInputProps()} />
            <EmptyInput isDisabled={isDisabled} isDragActive={isDragActive} />
          </Box>
        )}
      </Box>
    );
  }
);

const FileInput = ({
  value,
  getInputProps,
  isDisabled,
  onChange,
  getRootProps,
  isDragActive,
}) => {
  const thumb = {
    display: "block",
    borderRadius: 2,
    marginBottom: 8,
    marginRight: 8,
    width: 120,
    height: 120,
    padding: 4,
    boxSizing: "border-box",
  };

  const onClearFile = () => {
    onChange(null);
  };

  return (
    <Stack direction={{ base: "column", md: "row" }}>
      <HStack
        h="60px"
        cursor={isDisabled ? "default" : "pointer"}
        border="1px rgb(222, 226, 230) solid"
        borderRadius="15px"
        justifyContent="center"
        w={"100%"}
      >
        <InputGroup alignItems="center" h="60px">
          <Input
            fontSize="md"
            as={Flex}
            align="center"
            border="none"
            {...getRootProps()}
          >
            <Input {...getInputProps()} />
            <Text
              isTruncated
              as="span"
              ml="2"
              color={!isDisabled ? undefined : "gray"}
            >
              {isDragActive ? (
                <Text as="span" fontWeight="semibold">
                  Drop the files here...
                </Text>
              ) : (
                `Files selected : ${value.length}`
              )}
            </Text>
          </Input>

          {!isDisabled && (
            <InputRightElement zIndex={2}>
              <IconButton
                aria-label="Remove file"
                onClick={onClearFile}
                size="sm"
                mt={5}
                icon={<Icon as={UilTimes} boxSize="5" />}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </HStack>
    </Stack>
  );
};

const EmptyInput = ({ isDisabled, isDragActive, isDragReject }) => {
  const text = useMemo(
    () =>
      isDragActive ? (
        <Text as="span" fontWeight="semibold">
          Drop the files here...
        </Text>
      ) : isDragReject ? (
        <Text as="span" fontWeight="semibold">
          File type not accepted, sorry!
        </Text>
      ) : (
        <>
          Drag & Drop or {"   "}
          <Text as="span" fontWeight="semibold">
            Choose a pdf/docx File
          </Text>
        </>
      ),
    [isDragActive]
  );

  return (
    <HStack
      h="60px"
      cursor={isDisabled ? "default" : "pointer"}
      border="1px rgb(222, 226, 230) solid"
      borderRadius="15px"
      justifyContent="center"
    >
      <Icon
        as={UilImageUpload}
        boxSize="5"
        color={!isDisabled ? "black" : "gray"}
      />
      <Text as="span" ml="2" color={!isDisabled ? "black" : "gray"}>
        {text}
      </Text>
    </HStack>
  );
};

export const FileControl = ({ isDisabled, control, name }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <AttachmentInput {...field} isDisabled={isDisabled} />
      )}
    />
  );
};
