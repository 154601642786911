import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getComparablePlayers,
  getComparablePlayer,
  postComparablePlayer,
  putComparablePlayer,
  deleteComparablePlayer,
} from "../redux/comparablePlayers";

export const fetchComparablePlayers = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/comparablePlayers`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getComparablePlayers(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchComparablePlayer = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/comparablePlayers/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getComparablePlayer(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createComparablePlayer = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/comparablePlayers`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postComparablePlayer(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateComparablePlayer = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/comparablePlayers/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putComparablePlayer(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
