// Actions
const LOAD_ALL = "articles/LOAD_ALL";
const LOAD_UNIQUE = "articles/LOAD_UNIQUE";
const CREATE = "articles/CREATE";
const UPDATE = "articles/UPDATE";
const REMOVE_UNIQUE = "articles/REMOVE_UNIQUE";
const REMOVE_ALL = "articles/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.articles.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.article.id]: action.article,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.article.id]: {
            ...state.data[action.article.id],
            ...action.article,
          },
        },
      };
    case REMOVE_UNIQUE:
      return {
        ...state,
        data:
          Object.values(state.data || [])
            .filter((u) => u.id !== action.id)
            .reduce((a, b) => ((a[b?.id] = b), a), {}) || null,
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const getArticles = (articles) => ({ type: LOAD_ALL, articles });
export const getArticle = (article) => ({ type: LOAD_UNIQUE, article });
export const postArticle = (article) => ({ type: CREATE, article });
export const putArticle = (article) => ({ type: UPDATE, article });
export const deleteArticle = (id) => ({ type: REMOVE_UNIQUE, id });
export const deleteArticles = () => ({ type: REMOVE_ALL });
