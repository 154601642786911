import {
  Box,
  Input,
  Text,
  Spinner,
  InputGroup,
  Center,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function GeocoderInput({ onSelectAddress, children, disabled }) {
  const [value, setValue] = useState("");
  let bgDropDown = useColorModeValue("white", "gray.600");
  const textColor = useColorModeValue("gray.700", "white");

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const addressObj = results[0].address_components.reduce(
        (acc, component) => {
          const key = component.types[0];
          acc[key] = component.long_name;
          return acc;
        },
        {}
      );

      //Overlapping zipcodes might excliude locality - extract from formatted address
      if (!addressObj?.locality) {
        const addressComponents = results[0]?.formatted_address.split(", ");

        let locality = addressComponents[addressComponents.length - 3];
        if (locality) {
          addressObj.locality = locality;
        }
      }

      onSelectAddress({
        ...addressObj,
        ...latLng,
      });
      setValue("");
    } catch (err) {
      console.error("Error", err);
    }
  };

  return (
    <>
      {window?.google && (
        <PlacesAutocomplete
          value={value}
          onChange={setValue}
          onSelect={handleSelect}
          searchOptions={{
            types: ["geocode", "establishment"],
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <InputGroup position="relative" w="100%">
              {children}
              <Input
                {...getInputProps({
                  placeholder: "Search location ...",
                })}
                borderRadius="15px"
                alignItems={"center"}
                justifyContent={"center"}
                type="text"
                h="60px"
                autoComplete="off"
                cursor="pointer"
                fontWeight="semibold"
                fontSize="md"
                size="lg"
                disabled={disabled}
              />
              {suggestions && suggestions.length > 0 && (
                <Box
                  borderWidth="1px"
                  rounded="md"
                  shadow="md"
                  overflow="hidden"
                  position="absolute"
                  bg="white"
                  w="100%"
                  zIndex="1"
                  top="12"
                >
                  {loading ? (
                    <Center h="48" w="100%">
                      <Spinner size="sm" />
                    </Center>
                  ) : (
                    <>
                      {suggestions?.map((suggestion, idx) => (
                        <Box
                          as="button"
                          key={idx}
                          px="2"
                          py="2"
                          d="block"
                          w="100%"
                          textAlign="left"
                          cursor="pointer"
                          _hover={{ bg: "gray.100" }}
                          {...getSuggestionItemProps(suggestion)}
                          background={bgDropDown}
                        >
                          <Text color={textColor}>
                            {suggestion.description}
                          </Text>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              )}
            </InputGroup>
          )}
        </PlacesAutocomplete>
      )}
    </>
  );
}
