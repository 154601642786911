import { fetchPlayerData } from "api/spotrac";
import { mergeArrays } from "lib/utils";
import { useState } from "react";

const buffer = 2000;

const useSearchPlayer = () => {
  const [isSearching, setSearching] = useState(false);
  const [timeout, setSearchTimeout] = useState(null);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);

  const fetchingData = async (playerId) => {
    try {
      setError(null);
      const data = await fetchPlayerData(playerId);
      if (!data?.player?.details?.ids?.id) throw Error("Spotrac ID not found");
      setResults(data?.player);
    } catch (e) {
      if (e?.message?.includes("AxiosError: Network Error"))
        return setError("Spotrac ID not found");
      setError(e.message);
    } finally {
      setSearching(false);
    }
  };

  const searchHandler = (playerId) => {
    setSearching(true);
    if (timeout) clearTimeout(timeout);
    setSearchTimeout(setTimeout(() => fetchingData(playerId), buffer));
  };

  const formatContract = (
    playerDetails,
    contractDetails,
    marketValueDetails,
    formData,
    teams
  ) => {
    const details = contractDetails?.details;
    const breakdowns = contractDetails?.breakdown || [];
    const position = playerDetails?.position?.[0]?.name;
    const nflRank =
      marketValueDetails?.saved_as?.projection?.league_average_rank;
    const positionRank =
      marketValueDetails?.saved_as?.projection?.position_average_rank;

    return {
      years: details?.years || formData?.years,
      notes: details?.notes || formData?.notes,
      startYear: details?.start || formData?.startYear,
      endYear: details?.end || formData?.endYear,
      totalSalary: details?.value || formData?.totalSalary,
      averageSalary:
        Math.round(details?.value / details?.years) || formData?.averageSalary,
      position: position || formData?.position,
      nflRank: nflRank || formData?.nflRank,
      positionRank: positionRank || formData?.positionRank,
      freeAgentYear: details?.free_agent_year || formData?.freeAgentYear,
      breakdown: breakdowns.map((b) => {
        const breakdownYear = parseInt(b.year);
        const birthDate = new Date(
          playerDetails?.date_of_birth?.date_of_birth
        ).getFullYear();
        const age = breakdownYear - birthDate;

        return {
          age: age,
          teamId: teams.find((t) => b.team_name.includes(t.label))?.value,
          baseSalary: b.cash_base,
          capHit: b.cap_signing,
          deadCap: b.cap_cap,
          signingBonus: b.cash_signing,
          year: b.year,
          yearlyCash: b.cash_total,
        };
      }),
    };
  };

  const formatMarkerValue = (marketValueDetails, formData, spotracId) => {
    const details = marketValueDetails?.saved_as?.projection;
    const comparablePlayers =
      marketValueDetails?.saved_as?.comparablePlayers || [];

    return {
      fiveYearProjection: details?.value || formData?.fiveYearProjection,
      averageAnnualSalary: details?.average || formData?.averageAnnualSalary,
      nflRank: details?.league_average_rank || formData?.nflRank,
      positionRank: details?.position_average_rank || formData?.positionRank,
      comparablePlayers: comparablePlayers
        ?.filter((b) => b.player_id != spotracId)
        ?.map((b) => ({
          name: b.player_name,
          spotracId: b.player_id,
          contractLength: b.adjustedLength,
          contractValue: b.adjustedValue,
          contractAverageValue: b.average,
          ageSigned: b.player_age_signed,
        })),
    };
  };

  const updateData = async (formData, teams) => {
    let position = formData?.position;
    let contract = formData?.contract;
    let marketValue = formData?.marketValue;
    let teamId = formData?.teamId?.value;
    if (!formData.spotracId || formData.spotracId === 0)
      return { position, contract, marketValue };
    const spotracData = await fetchPlayerData(formData.spotracId);
    let playerDetails = spotracData?.player?.details;
    let contractDetails = spotracData?.player?.contracts?.[0];
    let marketValueDetails = spotracData?.player?.market_value;

    position = playerDetails?.position?.[0]?.name || formData?.position;
    teamId = teams.find((t) => playerDetails?.team?.name?.includes(t.label))
      ?.value;
    contract = formatContract(
      playerDetails,
      contractDetails,
      marketValueDetails,
      formData?.contract,
      teams
    );
    marketValue = formatMarkerValue(
      marketValueDetails,
      formData?.marketValue,
      formData?.spotracId
    );

    return { position, contract, marketValue, teamId };
  };

  return {
    isSearching,
    results,
    errorSearch: error,
    searchHandler,
    updateData,
  };
};

export default useSearchPlayer;
