// Actions
const LOAD_ALL = "contracts/LOAD_ALL";
const LOAD_UNIQUE = "contracts/LOAD_UNIQUE";
const UPDATE = "contracts/UPDATE";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.contracts.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.contract.id]: action.contract,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.contract.id]: {
            ...state.data[action.contract.id],
            ...action.contract,
          },
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const getContracts = (contracts) => ({ type: LOAD_ALL, contracts });
export const getContract = (contract) => ({ type: LOAD_UNIQUE, contract });
export const putContract = (contract) => ({ type: UPDATE, contract });
