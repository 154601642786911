import React, { useState } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export function SearchBar(props) {
  const mainTeal = useColorModeValue("red.300", "red.300");
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("white", "gray.800");

  const [search, setSearch] = useState("");

  const onChange = (value) => {
    setSearch(value);
    props.onSearch(value);
  };

  return (
    <InputGroup
      bg={inputBg}
      borderRadius="15px"
      w="200px"
      _focus={{ borderColor: { mainTeal } }}
      _active={{ borderColor: { mainTeal } }}
    >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover={{}}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{ boxShadow: "none" }}
            icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
          />
        }
      />
      <Input
        fontSize="xs"
        py="11px"
        placeholder="Type here..."
        borderRadius="inherit"
        value={search}
        onChange={(e) => onChange(e.target.value)}
      />
    </InputGroup>
  );
}
