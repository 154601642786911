import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { Text, Flex, Button, Grid } from "@chakra-ui/react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Control from "components/Forms/FormControl";
import InputAutofill from "components/Forms/InputAutofill";
import { playerTypes, collegePlayerTypes } from "lib/type";
import { playerStatusTypes } from "lib/type";
import { ImageControl } from "components/Forms/ImageInput";
import { InputControl, InputWrapper } from "../components";
import useUser from "./data";
import { userTypes } from "lib/type";
import { upperFirstLetter } from "lib/utils";

const UserDetails = () => {
  const {
    handleSubmit,
    register,
    control,
    errors,
    isEdit,
    setEdit,
    isLoading,
    isSearching,
    searchHandler,
    textColor,
    bgButton,
    teamsOptions,
    userId,
    userType,
    userLabel,
    updateMutation,
    updateHandler,
    errorSearch,
    events,
    watch,
    reset,
    agents
  } = useUser();

  const nextPaycheck = watch("nextPaycheck");
  const [prevPaycheck, setPrevPaycheck] = useState("");

  const pTypes = useMemo(() => {
    if (userType === userTypes.COLLEGE) return collegePlayerTypes;
    return playerTypes;
  }, [userType]);

  useEffect(() => {
    if (
      nextPaycheck &&
      nextPaycheck !== prevPaycheck &&
      Number(parseInt(nextPaycheck.replace(/,/g, "")))
    ) {
      const formattedPaycheck = parseInt(
        nextPaycheck.replace(/,/g, "")
      ).toLocaleString();

      setPrevPaycheck(formattedPaycheck);

      const formData = watch();
      reset({
        ...formData,
        nextPaycheck: formattedPaycheck,
      });
    }
  }, [nextPaycheck]);

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader>
        <Flex justify="space-between" align="center" minHeight="60px" w="100%">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            User {userId ? "Details" : "Creation"}
          </Text>
          <Button
            bg={bgButton}
            color="white"
            fontSize="xs"
            variant="no-hover"
            onClick={() => setEdit(!isEdit)}
          >
            {isEdit ? "CANCEL" : "EDIT"}
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <form onSubmit={handleSubmit(updateHandler)}>
            <Grid
              templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={6}
              align="center"
              w="100%"
              justify="center"
              py="1rem"
              my={5}
            >
              <InputControl
                errors={errors.firstName}
                label="First Name"
                isRequired={isEdit ? true : false}
              >
                <InputWrapper
                  disabled={!isEdit}
                  placeholder="Enter first name"
                  type="text"
                  register={register("firstName", {
                    required: "First Name is required",
                  })}
                />
              </InputControl>
              <InputControl
                errors={errors.lastName}
                label="Last Name"
                isRequired={isEdit ? true : false}
              >
                <InputWrapper
                  disabled={!isEdit}
                  placeholder="Enter last name"
                  type="text"
                  register={register("lastName", {
                    required: "Last Name is required",
                  })}
                />
              </InputControl>
              <InputControl
                errors={errors.email}
                label="Email"
                isRequired={isEdit ? true : false}
              >
                <InputWrapper
                  disabled={!isEdit}
                  placeholder="Enter email"
                  autoComplete="email"
                  type="email"
                  register={register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </InputControl>
              <InputControl errors={errors.password} label="Password">
                <InputWrapper
                  disabled={!isEdit}
                  placeholder="Enter password"
                  type="password"
                  register={register("password", {
                    minLength: {
                      value: 8,
                      message: "Minimum length should be 8",
                    },
                  })}
                />
              </InputControl>
              <Control errors={errors.userType} label="User Type" isRequired={true}>
                <InputAutofill
                  disabled={!isEdit}
                  options={Object.values(userTypes).map((t) => ({
                    value: t,
                    label: t,
                  }))}
                  placeholder="User Type"
                  register={register("userType", {
                    required: "User Type is required",
                  })}
                  control={control}
                  isMulti={false}
                  closeMenuOnSelect={true}
                />
              </Control>
              {[userTypes.PLAYER, userTypes.COLLEGE].includes(userType) && (
                <>
                  <InputControl
                    errors={errors.spotracId}
                    label="Spotrac ID"
                    isLoading={isSearching}
                  >
                    <InputWrapper
                      placeholder="Enter Spotrac ID"
                      type="number"
                      disabled={!isEdit}
                      onChange={({ target: { value } }) => searchHandler(value)}
                      register={register("spotracId", {
                        setValueAs: (value) =>
                          typeof value === "string" && value.trim() === ""
                            ? 0
                            : parseInt(value, 10),
                        validate: () => {
                          if (userType !== userTypes.PLAYER) {
                            return true;
                          }
                          return errorSearch !== null ? `${errorSearch}` : true;
                        },
                      })}
                    />
                  </InputControl>
                  <Control errors={errors.teamId} label="Team">
                    <InputAutofill
                      options={teamsOptions}
                      placeholder="Select Team"
                      register={register("teamId")}
                      control={control}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      disabled={!isEdit}
                    />
                  </Control>
                  <Control errors={errors.type} label="Type">
                    <InputAutofill
                      options={Object.values(pTypes).map((t) => ({
                        value: t,
                        label: t,
                      }))}
                      placeholder="Select Type"
                      register={register("type")}
                      control={control}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      disabled={!isEdit}
                    />
                  </Control>
                  <Control
                    errors={errors.status}
                    label="Status"
                    isRequired={true}
                  >
                    <InputAutofill
                      options={Object.values(playerStatusTypes).map((t) => ({
                        value: t,
                        label: t,
                      }))}
                      placeholder="Select Status"
                      register={register("status", {
                        required: "Status is required",
                      })}
                      control={control}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      disabled={!isEdit}
                    />
                  </Control>
                  <Control errors={errors.eventsId} label="Events">
                    <InputAutofill
                      options={events}
                      placeholder="Select Events"
                      register={register("eventsId")}
                      control={control}
                      isMulti={true}
                      disabled={!isEdit}
                    />
                  </Control>
                  <Control
                    errors={errors.status}
                    label="Assigned To"
                    isRequired={false}
                  >
                    <InputAutofill
                      options={agents}
                      placeholder="Select Agent"
                      register={register("agents")}
                      control={control}
                      isMulti={true}
                      closeMenuOnSelect={true}
                      disabled={!isEdit}
                    />
                  </Control>
                </>
              )}
              {userType !== userTypes.PLAYER &&
                userType !== userTypes.COLLEGE &&
                userType !== userTypes.PARENT &&
                userType !== userTypes.AGENT &&
                userType !== userTypes.SPOUSE &&
                userType && [
                  <Control errors={errors.teamId} label="Team" key="team">
                    <InputAutofill
                      options={teamsOptions}
                      placeholder="Select Team"
                      register={register("teamId")}
                      control={control}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      disabled={!isEdit}
                    />
                  </Control>,
                  userType !== userTypes.REPORTER && [
                    <InputControl
                      errors={errors.type}
                      label={`${upperFirstLetter(userLabel)} Type`}
                      isRequired={true}
                      key={`${userLabel}Type`}
                    >
                      <InputWrapper
                        placeholder={`Enter ${userLabel} type`}
                        type="text"
                        register={register(`${userLabel}Type`, {
                          required: `${upperFirstLetter(
                            userLabel
                          )} Type is required`,
                        })}
                        disabled={!isEdit}
                      />
                    </InputControl>,

                    <InputControl
                      errors={errors.nextPaycheck}
                      label="Next Paycheck"
                      key="nextPaycheck"
                    >
                      <InputWrapper
                        placeholder="Enter next paycheck"
                        type="text"
                        register={register("nextPaycheck")}
                        disabled={!isEdit}
                      />
                    </InputControl>,
                  ],
                  ,
                  <InputControl
                    errors={errors.yearsWithCurrentTeam}
                    label="Years with Current Team"
                    key="yearsCurrentTeam"
                  >
                    <InputWrapper
                      placeholder="Enter years with current team"
                      type="number"
                      register={register("yearsWithCurrentTeam")}
                      disabled={!isEdit}
                    />
                  </InputControl>,
                  <InputControl
                    errors={errors.yearsActive}
                    label="Years Active"
                    key="yearsActive"
                  >
                    <InputWrapper
                      placeholder="Enter years active"
                      type="number"
                      register={register("yearsActive")}
                      disabled={!isEdit}
                    />
                  </InputControl>,
                ]}
              {userType === userTypes.AGENT && [
                <InputControl
                  errors={errors.yearsWithCurrentCompany}
                  label="Years with Current Company"
                  key="yearsCurrentCompany"
                >
                  <InputWrapper
                    placeholder="Enter years with current company"
                    type="number"
                    register={register("yearsWithCurrentCompany")}
                    disabled={!isEdit}
                  />
                </InputControl>,
                <InputControl
                  errors={errors.yearsActive}
                  label="Years Active"
                  key="yearsActive"
                >
                  <InputWrapper
                    placeholder="Enter years active"
                    type="number"
                    register={register("yearsActive")}
                    disabled={!isEdit}
                  />
                </InputControl>,
              ]}
            </Grid>
            <Control errors={errors.image} label="Profile Image">
              <ImageControl
                control={control}
                name={"image"}
                isDisabled={!isEdit}
              />
            </Control>
            <Flex justifyContent="center" alignItems="center" hidden={!isEdit}>
              <Button
                isLoading={updateMutation.loading || isLoading || isSearching}
                type="submit"
                bg="red.300"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                maxW={"200px"}
                _hover={{ bg: "red.200" }}
                _active={{ bg: "red.400" }}
              >
                Update
              </Button>
            </Flex>
          </form>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default UserDetails;
