import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

const Control = ({
  children,
  label,
  errors,
  isRequired,
  hidden,
  ...props
}) => {
  return (
    <FormControl
      {...props}
      isInvalid={errors}
      hidden={hidden}
      isRequired={isRequired}
    >
      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
        {label}
      </FormLabel>
      {children}
      <FormErrorMessage>{errors && errors.message}</FormErrorMessage>
    </FormControl>
  );
};

export default Control;
