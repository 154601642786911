import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { updatePlayerDeal } from 'api/playerDeals';
import { createPlayerDeal } from 'api/playerDeals';
import { fetchPlayerDeals } from 'api/playerDeals';
import { useReduxQuery } from 'hooks/useReduxQuery';
import React from 'react'
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const formatMoney = (value) => {
   return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   }).format(value)
}
const format = (val) => '$' + val
const parse = (val) => val.replace(/^\$/, '')

const defaultValues = {
   company: '',
   duration: '',
   totalValue: 0,
   weeklyPay: 0,
}

export default function PlayerDeal() {
   const { userId } = useParams();
   const dispatch = useDispatch()
   const { loadingPlayerDeals, playerDeals } = useReduxQuery("PlayerDeals", () => fetchPlayerDeals(userId));

   const deals = Object.values(playerDeals || {}).filter(pd => Number(pd.userId) === Number(userId))

   const { isOpen, onOpen, onClose } = useDisclosure()
   const [activeDealId, setActiveDealId] = useState(undefined)
   const [isSaving, setIsSaving] = useState(false)

   const {
      handleSubmit,
      reset,
      control,
      formState: { errors },
   } = useForm({
      defaultValues
   });

   const handleModalOpen = (dealId) => {
      setActiveDealId(dealId)
      const activeDealValues = dealId
         ? deals.find(d => d.id === dealId)
         : defaultValues

      reset({
         company: activeDealValues.company,
         duration: activeDealValues.duration,
         totalValue: activeDealValues.totalValue,
         weeklyPay: activeDealValues.weeklyPay,
      })
      onOpen()
   }

   const onSubmit = async (data) => {
      try {
         setIsSaving(true)

         if (activeDealId) {
            await dispatch(updatePlayerDeal({
               id: activeDealId,
               ...data
            }))
         } else {
            await dispatch(createPlayerDeal({
               userId,
               ...data
            }))
         }
         setIsSaving(false)
         onClose()
      } catch (error) {
         alert(error)
      }
   }


   return (
      <Box shadow="sm" borderRadius="2xl" padding="6" backgroundColor="white">
         <Flex mb="4">
            <Heading as="h2" fontSize="xl">Player Deals</Heading>
            <Button ml="auto" colorScheme='blue' onClick={() => handleModalOpen()}>Add a Deal</Button>
         </Flex>
         {loadingPlayerDeals ? (
            <Center h="12">
               <Spinner thickness="5px" size="lg" />
            </Center>
         ) : deals.length > 0 ? (
            <Table>
               <Thead>
                  <Tr>
                     <Th>Company</Th>
                     <Th>Deal Length</Th>
                     <Th>Total Value</Th>
                     <Th>Weekly Pay</Th>
                     <Th></Th>
                  </Tr>
               </Thead>
               <Tbody>
                  {deals.map(deal => (
                     <Tr key={deal.id}>
                        <Td>{deal.company}</Td>
                        <Td>{deal.duration}</Td>
                        <Td>{isNaN(deal.totalValue) ? '-' : `${formatMoney(deal.totalValue)}`}</Td>
                        <Td>{isNaN(deal.weeklyPay) ? '-' : `${formatMoney(deal.weeklyPay)}`}</Td>
                        <Td>
                           <Button
                              colorScheme='blue'
                              variant='link'
                              onClick={() => handleModalOpen(deal.id)}
                           >Edit</Button>
                        </Td>
                     </Tr>
                  ))}
               </Tbody>
            </Table>
         ) : (
            <Center h="12">
               <Text>Player has no deals</Text>
            </Center>
         )}

         <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
               <ModalHeader>
                  {activeDealId ? 'Edit Deal' : 'Add Deal'}
               </ModalHeader>
               <ModalCloseButton />
               <form onSubmit={handleSubmit(onSubmit)}>
                  <ModalBody>
                     <Grid gap="4">
                        <GridItem>
                           <Controller
                              control={control}
                              name="company"
                              render={({ field: { onChange, value } }) => (
                                 <FormControl>
                                    <FormLabel>Company</FormLabel>
                                    <Input onChange={onChange} value={value} />
                                 </FormControl>
                              )}
                           />
                        </GridItem>
                        <GridItem>
                           <Controller
                              control={control}
                              name="duration"
                              render={({ field: { onChange, value } }) => (
                                 <FormControl>
                                    <FormLabel>Deal Length</FormLabel>
                                    <Input onChange={onChange} value={value} />
                                 </FormControl>
                              )} />
                        </GridItem>
                        <GridItem>
                           <Controller
                              control={control}
                              name="totalValue"
                              render={({ field: { onChange, value } }) => (
                                 <FormControl>
                                    <FormLabel>Total Value</FormLabel>
                                    <NumberInput
                                       onChange={(val) => onChange(parse(val))}
                                       value={format(value)}
                                       precision={2}
                                       pattern='\$[0-9]*(.[0-9]+)?'
                                    >
                                       <NumberInputField />
                                    </NumberInput>
                                 </FormControl>
                              )} />
                        </GridItem>
                        <GridItem>
                           <Controller
                              control={control}
                              name="weeklyPay"
                              render={({ field: { onChange, value } }) => (
                                 <FormControl>
                                    <FormLabel>Weekly Pay</FormLabel>
                                    <NumberInput
                                       onChange={(val) => onChange(parse(val))}
                                       value={format(value)}
                                       precision={2}
                                       pattern='\$[0-9]*(.[0-9]+)?'
                                    >
                                       <NumberInputField />
                                    </NumberInput>
                                 </FormControl>
                              )} />
                        </GridItem>
                     </Grid>
                  </ModalBody>
                  <ModalFooter gap="4">
                     <Button variant='ghost' onClick={onClose}>Cancel</Button>
                     <Button type="submit" colorScheme='blue' mr={3} isLoading={isSaving} loadingText="Saving...">
                        Save
                     </Button>
                  </ModalFooter>
               </form>
            </ModalContent>
         </Modal>
      </Box>
   )
}
