import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  Button,
  Input,
  Grid,
  Textarea,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { createArticle, updateArticle } from "api/articles";
import { fetchArticle } from "api/articles";
import InputAutofill from "components/Forms/InputAutofill";
import InputCreatefill from "components/Forms/InputCreatefill";
import { FileControl } from "components/Forms/AttachmentInput";
import Control from "components/Forms/FormControl";
import { fetchTeams } from "api/teams";
import { fetchUsers } from "api/users";
import { leagueTypes } from "lib/type";
import { useSuccessToast, useErrorToast } from "hooks/useToastNotification";
import { createUpload } from "api/upload";
import { useDispatch } from "react-redux";
import mime from "mime";
import { documentTypes } from "lib/type";

const ArticleDetails = () => {
  const { articleId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const isCreate = location.pathname.includes("create");

  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const [isEdit, setEdit] = useState(isCreate ? true : false);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const createdHandler = () => {
    reset();
    reset({
      title: "",
      description: "",
      videoLink: "",
      anchorDocument: null,
      cbaAnchor: "",
      teamsId: null,
      usersId: null,
      tags: [],
      contractKey: null,
    });
  };
  const updatedHandler = () => setEdit(false);

  const createMutation = useReduxMutation(createArticle, createdHandler);
  const updateMutation = useReduxMutation(updateArticle, updatedHandler);
  const [isLoading, setLoading] = useState(false);

  useSuccessToast(createMutation);
  useSuccessToast(updateMutation);
  useErrorToast(createMutation);
  useErrorToast(updateMutation);

  const { article } = articleId
    ? useReduxQuery("Article", () => fetchArticle(articleId), {
        selector: (state) => state.articles?.data?.[articleId],
      })
    : [];

  const { users } = useReduxQuery("Users", fetchUsers, {
    selector: (state) => [
      { value: 0, label: "All Users" },
      ...Object.values(state.users.data || [])?.map((u) => ({
        value: u.id,
        label: `${u.firstName} ${u.lastName}`,
      })),
    ],
  });

  const { teams } = useReduxQuery("Teams", fetchTeams, {
    selector: (state) =>
      Object.values(state.teams.data || [])?.map((t) => ({
        value: t.id,
        label: `${t.city} ${t.name}`,
        data: t,
      })),
  });

  const teamsOptions = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        label: league,
        options: [
          { value: `0-${league}`, label: `All ${league}`, data: {} },
          ...teams
            ?.filter(
              (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
            )
            .sort((a, b) => a.label.localeCompare(b.label)),
        ],
      })),
    [teams]
  );

  const documentsOptions = useMemo(
    () =>
      Object.keys(documentTypes).map((document) => ({
        label: documentTypes[document],
        value: document,
      })),
    [teams]
  );

  const handleDeleteFile = (key) => {
    setDeletedFiles([...deletedFiles, key]);
  };

  const uploadFiles = async (data) => {
    for (const file of data.files) {
      const formData = new FormData();
      formData.append("File", file);
      const key = await dispatch(createUpload(formData, "contract_key"));
      data.contractKey.push(key.files);
    }
  };

  const createFileUpload = async (data) => {
    if (data.files) {
      try {
        setLoading(true);
        data.contractKey = [];
        await uploadFiles(data);
        setLoading(false);
        createHandler(data);
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    } else {
      createHandler(data);
    }
  };

  const createHandler = async (data) => {
    createMutation.submit({
      ...data,
      tags: data?.tags?.map((a) => a.value),
      teamsId: data?.teamsId
        ?.map((a) =>
          a?.value?.toString().includes("0-")
            ? teams
                .filter((t) =>
                  t?.data?.league
                    ?.toUpperCase()
                    .includes(a.value?.split("0-")[1])
                )
                .map((a) => a.value)
            : a.value
        )
        .flat(),
      usersId: data?.usersId
        ?.map((a) =>
          a.value === 0 ? users.shift() && users.map((u) => u.value) : a.value
        )
        .flat(),
      contractKey: data?.contractKey?.toString(),
      anchorDocument: data?.anchorDocument?.value,
    });
  };

  const handleContractKey = (key, deletedFiles) => {
    if (key) {
      const keys = Array.isArray(key) ? key : key.split(",");
      return keys.filter((k) => !deletedFiles.includes(k));
    }
    return [];
  };

  const updateFileUpload = async (data) => {
    delete data?.fileurls;
    if (data.files) {
      try {
        setLoading(true);
        data.contractKey =
          data?.contractKey?.length > 1
            ? data?.contractKey?.includes(",")
              ? data?.contractKey?.split(",")
              : [data?.contractKey]
            : [];
        await uploadFiles(data);
        data.contractKey = handleContractKey(data.contractKey, deletedFiles);
        setLoading(false);
        updateHandler(data);
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    } else {
      data.contractKey = data.contractKey
        ? data.contractKey
            .split(",")
            .filter((key) => !deletedFiles.includes(key))
        : [];
      updateHandler(data);
    }
  };

  const updateHandler = async (data) =>
    updateMutation.submit({
      id: articleId,
      ...data,
      tags: data?.tags?.map((a) => a.value),
      teamsId: data?.teamsId
        ?.map((a) =>
          a?.value?.toString().includes("0-")
            ? teams
                .filter((t) =>
                  t?.data?.league
                    ?.toUpperCase()
                    .includes(a.value?.split("0-")[1])
                )
                .map((a) => a.value)
            : a.value
        )
        .flat(),
      usersId: data?.usersId
        ?.map((a) =>
          a.value === 0 ? users.shift() && users.map((u) => u.value) : a.value
        )
        .flat(),
      contractKey: data?.contractKey?.toString(),
      anchorDocument: data?.anchorDocument?.value,
    });

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const documentAnchor = watch("anchorDocument");

  useEffect(() => {
    if (article) {
      const data = {
        ...article,
        tags: article?.tags?.map((t) => ({ value: t, label: t })),
        usersId: article?.usersAssigned?.map((u) => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
        })),
        teamsId: article?.teamsAssigned?.map((t) => ({
          value: t.id,
          label: t.name,
        })),
        anchorDocument: article?.anchorDocument
          ? {
              value: article?.anchorDocument,
              label: documentTypes[article?.anchorDocument],
            }
          : null,
      };
      reset(data);
    }
  }, [article]);
  const selectedTags = watch("tags");
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader>
          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Article {articleId ? "Details" : "Creation"}
            </Text>
            <Button
              hidden={isCreate}
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="no-hover"
              onClick={() => setEdit(!isEdit)}
            >
              {isEdit ? "CANCEL" : "EDIT"}
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <form
              onSubmit={handleSubmit(
                articleId ? updateFileUpload : createFileUpload
              )}
            >
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap={6}
                align="center"
                w="100%"
                justify="center"
                py="1rem"
                my={5}
              >
                <Control
                  errors={errors.title}
                  label="Article Title"
                  isRequired={isEdit ? true : false}
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter Title"
                    type="text"
                    register={register("title", {
                      required: "Title is required",
                    })}
                  />
                </Control>
                <Control
                  errors={errors.description}
                  label="Description"
                  isRequired={isEdit ? true : false}
                >
                  <TextAreaWrapper
                    disabled={!isEdit}
                    placeholder="Add your content here..."
                    type="text"
                    register={register("description", {
                      required: "Description is required",
                    })}
                  />
                </Control>
                <Control errors={errors.anchorDocument} label="Anchor Document">
                  <InputAutofill
                    disabled={!isEdit}
                    options={documentsOptions}
                    placeholder="Anchor Document"
                    register={register("anchorDocument")}
                    control={control}
                    isMulti={false}
                  />
                </Control>
                {!!documentAnchor && (
                  <Control
                    isRequired={isEdit ? !!documentAnchor : false}
                    errors={errors.cbaAnchor}
                    label="Page Anchor"
                  >
                    <InputWrapper
                      disabled={!isEdit}
                      placeholder="Add CBA page number"
                      type="text"
                      register={register("cbaAnchor", {
                        required: {
                          value: !!documentAnchor,
                          message: "CBA Anchor is required",
                        },
                      })}
                    />
                  </Control>
                )}
                <Control
                  errors={errors.videoLink}
                  label="Video Link"
                  isRequired={false}
                >
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Add Video Link"
                    type="text"
                    register={register("videoLink")}
                  />
                </Control>
                <Control errors={errors.tags} label="Tags">
                  <InputAutofill
                    disabled={!isEdit}
                    options={[
                      {
                        value: "Career",
                        label: "Career",
                      },
                      {
                        value: "Contract",
                        label: "Contract",
                      },
                      {
                        value: "League",
                        label: "League",
                      },
                      {
                        value: "Legal",
                        label: "Legal",
                      },
                      {
                        value: "professionaldevelopment",
                        label: "Professional Development",
                      },
                      {
                        value: "Medical",
                        label: "Medical",
                      },
                      {
                        value: "Benefits",
                        label: "Benefits",
                      },
                      {
                        value: "Off-Season",
                        label: "Off-Season",
                      },
                      {
                        value: "Pre-Draft",
                        label: "Pre-Draft",
                      },
                      {
                        value: "contractrepository",
                        label: "Contract Repository",
                      },
                      {
                        value: "strategicpartners",
                        label: "Strategic Partners",
                      },
                    ]}
                    placeholder="Select Tags"
                    register={register("tags")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.usersId} label="User Restriction">
                  <InputAutofill
                    disabled={!isEdit}
                    options={users}
                    placeholder="Select Users"
                    register={register("usersId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.teamsId} label="Team Restriction">
                  <InputAutofill
                    disabled={!isEdit}
                    options={teamsOptions}
                    placeholder="Select Teams"
                    register={register("teamsId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
              </Grid>
              {selectedTags &&
                selectedTags.some(
                  (tag) => tag.value === "contractrepository"
                ) && (
                  <Control errors={errors.files} label="Attach Files ">
                    <FileControl
                      control={control}
                      name={"files"}
                      isDisabled={!isEdit}
                      preview={"contractKey"}
                    />
                  </Control>
                )}
              {article?.contractKey && (
                <Box>
                  <Box
                    display={"flex"}
                    minW={0}
                    overflow={"hidden"}
                    paddingTop={"10px"}
                  >
                    <List spacing={2}>
                      {article.contractKey.split(",").map((key, index) => {
                        const parts = key.split("~");
                        const name = parts[0];
                        const extension = parts[1].slice(
                          parts[1].lastIndexOf(".")
                        );
                        const FileName = name + extension;
                        return (
                          <ListItem
                            key={index}
                            fontSize="sm"
                            fontWeight="normal"
                            display="flex"
                            alignItems="center"
                          >
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            <Text
                              textDecoration={
                                deletedFiles.includes(key)
                                  ? "line-through"
                                  : "none"
                              }
                              flex="1"
                            >
                              {FileName}
                            </Text>
                            {isEdit && (
                              <Button
                                variant="ghost"
                                color="gray.400"
                                size="sm"
                                ml={2}
                                textAlign={"center"}
                                onClick={() => handleDeleteFile(key)}
                              >
                                Delete
                              </Button>
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Box>
              )}
              <Flex
                justifyContent="center"
                alignItems="center"
                hidden={!isEdit}
              >
                <Button
                  isLoading={
                    createMutation.loading ||
                    updateMutation.loading ||
                    isLoading
                  }
                  type="submit"
                  bg="red.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  maxW={"200px"}
                  _hover={{ bg: "red.200" }}
                  _active={{ bg: "red.400" }}
                >
                  {isCreate ? "Create" : "Update"}
                </Button>
              </Flex>
            </form>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ArticleDetails;

const InputWrapper = ({ children: Children, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
      />
    </Flex>
  );
};

export const TextAreaWrapper = ({ children: Children, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Textarea
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="200px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
      />
    </Flex>
  );
};
