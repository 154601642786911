import React, { useState, useEffect, useMemo } from "react";
import {
  Text,
  Flex,
  useColorModeValue,
  Button,
  Input,
  Grid,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { createNotification } from "api/notifications";
import { fetchUsers } from "api/users";
import { fetchNotification } from "api/notifications";
import { fetchContacts } from "api/contacts";
import InputAutofill from "components/Forms/InputAutofill";
import { fetchArticles } from "api/articles";
import { fetchTeams } from "api/teams";
import Control from "components/Forms/FormControl";
import { fetchEvents } from "api/events";
import { notificationTypes, contactTypes } from "lib/type";
import { leagueTypes } from "lib/type";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";

const NotificationDetails = () => {
  const { notificationId } = useParams();
  const location = useLocation();
  const isCreate = location.pathname.includes("send");

  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const [isEdit, setEdit] = useState(isCreate ? true : false);

  const createdHandler = () => {
    reset();
    reset({
      objectId: null,
      teamsId: null,
      usersId: null,
    });
  };

  const createMutation = useReduxMutation(createNotification, createdHandler);

  useSuccessToast(createMutation);
  useErrorToast(createMutation);

  const createHandler = async (data) => {
    createMutation.submit({
      ...data,
      objectId: data.objectId?.value,
      type: data.objectId?.type || notificationTypes.GLOBAL,
      teamsId: data?.teamsId
        ?.map((a) =>
          a?.value?.toString().includes("0-")
            ? teams
                .filter((t) =>
                  t?.data?.league
                    ?.toUpperCase()
                    .includes(a.value?.split("0-")[1])
                )
                .map((a) => a.value)
            : a.value
        )
        .flat(),
      usersId: data?.usersId
        ?.map((a) =>
          a.value === 0 ? users.shift() && users.map((u) => u.value) : a.value
        )
        .flat(),
    });
  };

  const { notification } = notificationId
    ? useReduxQuery(
        "Notification",
        () => notificationId && fetchNotification(notificationId),
        {
          selector: (state) => state.notifications.data?.[notificationId],
          updates: [notificationId],
        }
      )
    : [];

  const { users } = useReduxQuery("Users", fetchUsers, {
    selector: (state) => [
      { value: 0, label: "All Users" },
      ...Object.values(state.users.data || [])?.map((u) => ({
        value: u.id,
        label: `${u.firstName} ${u.lastName}`,
      })),
    ],
  });

  const { contacts } = useReduxQuery("Contacts", fetchContacts, {
    selector: (state) =>
      Object.values(state.contacts.data || [])?.map((c) => ({
        value: c.id,
        label: `${c.firstName} ${c.lastName}`,
        type:
          c.type === contactTypes.PROFESSIONAL_SERVICES
            ? notificationTypes.PROFESSIONAL_SERVICES
            : c.type === contactTypes.LEAGUE_CONTACT
            ? notificationTypes.LEAGUE_CONTACT
            : notificationTypes.CONTACT,
      })),
  });
  const { articles } = useReduxQuery("Articles", fetchArticles, {
    selector: (state) =>
      Object.values(state.articles.data || [])?.map((a) => ({
        value: a.id,
        label: a.title,
        type: notificationTypes.EDUCATION,
      })),
  });

  const { events } = useReduxQuery("Events", fetchEvents, {
    selector: (state) =>
      Object.values(state.events.data || [])?.map((e) => ({
        value: e.id,
        label: e.title,
        type: notificationTypes.EVENT,
      })),
  });
  const { teams } = useReduxQuery("Teams", fetchTeams, {
    selector: (state) =>
      Object.values(state.teams.data || [])?.map((t) => ({
        value: t.id,
        label: `${t.city} ${t.name}`,
        data: t,
      })),
  });

  const allLeague = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        value: 0,
        label: `All ${league}`,
        data: {
          league,
          teams: teams
            ?.filter(
              (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
            )
            .sort((a, b) => a.label.localeCompare(b.label)),
        },
      })),
    []
  );

  if (allLeague) {
    allLeague.map((a) => {
      teams.push(a);
    });
  }

  const teamsOptions = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        label: league,
        options: teams
          ?.filter(
            (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
          )
          .sort((a, b) => a.label.localeCompare(b.label)),
      })),
    [teams]
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (notification)
      reset({
        ...notification,
        objectId:
          notification.type === notificationTypes.CONTACT ||
          notification.type === notificationTypes.LEAGUE_CONTACT ||
          notification.type === notificationTypes.PROFESSIONAL_SERVICES
            ? {
                value: notification.contact.id,
                label: `${notification.contact?.firstName} ${notification.contact?.lastName}`,
              }
            : notification.type === notificationTypes.EDUCATION
            ? {
                value: notification.article.id,
                label: notification.article?.title,
              }
            : notification.type === notificationTypes.EVENT
            ? {
                value: notification.event?.id,
                label: notification.event?.title,
              }
            : null,
        usersId: notification.users?.map((u) => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`,
        })),
        teamsId: notification.teams?.map((t) => ({
          value: t.id,
          label: t.name,
        })),
      });
  }, [notification]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader>
          <Flex
            justify="space-between"
            align="center"
            minHeight="60px"
            w="100%"
          >
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Notification {notificationId ? "Details" : "Creation"}
            </Text>
            <Button
              hidden={isCreate || !isEdit}
              bg={bgButton}
              color="white"
              fontSize="xs"
              variant="no-hover"
              onClick={() => setEdit(!isEdit)}
            >
              {isEdit ? "CANCEL" : ""}
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <form onSubmit={handleSubmit(createHandler)}>
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap={6}
                align="center"
                w="100%"
                justify="center"
                py="1rem"
                my={5}
              >
                <Control errors={errors.title} label="Title" isRequired={true}>
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter title"
                    type="text"
                    register={register("title", {
                      required: "Title is required",
                    })}
                  />
                </Control>
                <Control errors={errors.description} label="Description">
                  <InputWrapper
                    disabled={!isEdit}
                    placeholder="Enter description"
                    type="text"
                    register={register("description")}
                  />
                </Control>
                <Control errors={errors.contactsId} label="Link Element">
                  <InputAutofill
                    disabled={!isEdit}
                    options={[
                      {
                        label: notificationTypes.CONTACT,
                        options: contacts.filter(
                          (c) => c.type === notificationTypes.CONTACT
                        ),
                      },
                      {
                        label: notificationTypes.PROFESSIONAL_SERVICES,
                        options: contacts.filter(
                          (a) =>
                            a.type === notificationTypes.PROFESSIONAL_SERVICES
                        ),
                      },
                      {
                        label: notificationTypes.LEAGUE_CONTACT,
                        options: contacts.filter(
                          (a) => a.type === notificationTypes.LEAGUE_CONTACT
                        ),
                      },
                      { label: "Articles", options: articles },
                      { label: "Events", options: events },
                    ]}
                    placeholder="Select Element"
                    register={register("objectId")}
                    control={control}
                    isMulti={false}
                    hasStickyGroupHeaders={true}
                    closeMenuOnSelect={true}
                  />
                </Control>
                <Control errors={errors.usersId} label="Users">
                  <InputAutofill
                    disabled={!isEdit}
                    options={users}
                    placeholder="Select Users"
                    register={register("usersId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
                <Control errors={errors.teamsId} label="Teams">
                  <InputAutofill
                    disabled={!isEdit}
                    options={teamsOptions}
                    placeholder="Select Teams"
                    register={register("teamsId")}
                    control={control}
                    isMulti={true}
                  />
                </Control>
              </Grid>
              <Flex
                justifyContent="center"
                alignItems="center"
                hidden={!isEdit}
              >
                <Button
                  isLoading={createMutation.loading}
                  type="submit"
                  bg="red.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  maxW={"200px"}
                  _hover={{ bg: "red.200" }}
                  _active={{ bg: "red.400" }}
                >
                  {isCreate ? "Send" : "Update"}
                </Button>
              </Flex>
            </form>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default NotificationDetails;

const InputWrapper = ({ children: Children, ...props }) => {
  const borderColor = useColorModeValue("#dee2e6", "gray.500");

  return (
    <Flex
      bg="transparent"
      borderRadius="15px"
      width="100%"
      border="1px solid"
      borderColor={borderColor}
      align="center"
      mb={{ sm: "24px", md: "0px" }}
      me={{ sm: "0px", md: "24px" }}
    >
      <Input
        {...props}
        py={2}
        borderRadius="15px"
        border="none"
        h="60px"
        fontSize="md"
        size="lg"
        fontWeight="semibold"
        {...props.register}
      />
    </Flex>
  );
};
