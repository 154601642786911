import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lowerFirstLetter, wait } from "../lib/utils";

export const useReduxQuery = (
  key = "Key",
  action,
  options = { updates: [] }
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);

  const data = useSelector(
    options.selector ||
      ((state) =>
        state[`${lowerFirstLetter(key)}`] &&
        state[`${lowerFirstLetter(key)}`].data)
  );

  const getData = useCallback(
    async (setAction) => {
      try {
        setAction(true);
        await dispatch(action());
        setAction(false);
      } catch (error) {
        setAction(false);
        setError(error.message);
      }
    },
    [dispatch, ...(options.updates || [])]
  );

  const refresh = () => getData(setRefreshing);

  useEffect(() => {
    !options.focus && getData(setLoading);
    return () => {};
  }, [getData]);

  return {
    [`loading${key}`]: loading,
    [`refreshing${key}`]: refreshing,
    [`${lowerFirstLetter(key)}`]: data,
    [`error${key}`]: error,
    [`refresh${key}`]: refresh,
  };
};

export const useReduxMutation = (action, validation) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const submit = useCallback(async (data) => {
    try {
      setError(null);
      setLoading(true);
      await wait(1000);
      await dispatch(action(data));
      setSuccess(true);
      validation && validation();
      setLoading(false);
    } catch (error) {
      setSuccess(false);
      //throw error and catch if needed instead of set
      setError(error.message);
      setLoading(false);
    }
  }, []);

  return {
    submit,
    loading,
    error,
    success,
  };
};
