import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getNotifications,
  getNotification,
  postNotification,
  archivesNotification,
} from "../redux/notifications";

export const fetchNotifications = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/notifications`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getNotifications(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchNotification = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/notifications/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getNotification(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createNotification = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/notifications`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postNotification(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const archiveNotification = (id, value) => async (
  dispatch,
  getState
) => {
  try {
    const json = await axiosHandler({
      url: `admin/notifications/${id}`,
      method: "PUT",
      data: { value },
      state: getState(),
    });
    await dispatch(archivesNotification(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
