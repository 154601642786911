export const leagueContactTypes = Object.freeze({
  COACH: "Coach",
  DIRECTOR: "Director",
});

export const notificationTabTypes = Object.freeze({
  GLOBAL: "Global",
  EVENT: "Events",
  EDUCATION: "Education",
  CONTACT: "Contacts",
  ARCHIVED: "Archived",
});

export const notificationTypes = Object.freeze({
  GLOBAL: "Global",
  EVENT: "Events",
  EDUCATION: "Educations",
  PROFESSIONAL_SERVICES: "Professional Services",
  LEAGUE_CONTACT: "League Contacts",
  CONTACT: "Contacts",
});

export const playerTypes = Object.freeze({
  NONE: "None",
  ROOKIE: "Rookie",
  VETERAN: "Veteran",
  VESTED_VETERAN: "Vested Veteran",
});
export const collegePlayerTypes = Object.freeze({
  FRESHMAN: "Freshman",
  SOPHOMORE: "Sophomore",
  JUNIOR: "Junior",
  SENIOR: "Senior",
});

export const playerStatusTypes = Object.freeze({
  PROSPECT: "Prospect",
  FREE_AGENT: "Free Agent",
  ACTIVE: "Active",
  INJURED: "Injured Reserve",
  RETIRED: "Retired",
  PUP: "PUP",
  NFI: "NFI",
  PRACTICE_SQUAD: "Practice Squad",
  DRAFT_PROSPECT: "Draft Prospect",
  FRESHMAN: "Freshman",
  REDSHIRT_FRESHMAN: "Redshirt Freshman",
  SOPHOMORE: "Sophomore",
  REDSHIRT_SOPHOMORE: "Redshirt Sophomore",
  JUNIOR: "Junior",
  REDSHIRT_JUNIOR: "Redshirt Junior",
  SENIOR: "Senior",
  REDSHIRT_SENIOR: "Redshirt Senior",
  GRADUATE: "Graduate",
});

export const leagueTypes = Object.freeze({
  NFL: "NFL",
  UFL: "UFL",
  CFL: "CFL",
  NFLPA: "NFLPA",
  FBS: "FBS",
});

export const leagueStatusRoles = Object.freeze({
  "General Manager": "General Manager",
  "VP of Player Personnel": "VP of Player Personnel",
  "Director of College Scouting": "Director of College Scouting",
  "Assistant Director of Pro Scouting": "Assistant Director of Pro Scouting",
});

export const leagueServices = Object.freeze({
  Management: "Management",
  "Pro Personnel": "Pro Personnel",
  "College Personnel": "College Personnel",
  "Scouting Assistant": "Scouting Assistant",
  Coach: "Coach",
  "Player Development": "Player Development",
  "Social Media": "Social Media",
  Payroll: "Payroll",
  NFLPA: "NFLPA",
});

export const contactTypes = Object.freeze({
  PROFESSIONAL_SERVICES: "professional_services",
  LEAGUE_CONTACT: "league_contact",
  MARKETING_CONTACT: "marketing_contact",
});

export const userTypes = Object.freeze({
  COACH: "Coach",
  EXECUTIVE: "Executive",
  PLAYER: "Professional Player",
  COLLEGE: "College Player",
  REPORTER: "Reporter",
  PARENT: "Parent",
  AGENT: "Agent",
  SPOUSE: "Spouse",
});

export const defaultServices = Object.freeze({
  LAWYER: "Lawyer",
  DOCTOR: "Doctor",
});

export const lawyerRoles = Object.freeze({
  BRANDING_TRADEMARKS: "Branding & Trademarks",
  BUSINESS_FORMATION: "Business Formation",
  DIVORCE: "Divorce",
  PERSONAL_INJURY: "Personal Injury",
  TRUSTS_ESTATES: "Trusts & Estates",
  WORKERS_COMP: "Workers Comp",
  TAXES_IRS: "Taxes/IRS",
});

export const doctorRoles = Object.freeze({
  ANKLE: "Ankle",
  ELBOW: "Elbow",
  FOOT: "Foot",
  HAND: "Hand",
  HEAD: "Head",
  HIP: "Hip",
  KNEE: "Knee",
  NECK_SPINE: "Neck/Spine",
  SHOULDER: "Shoulder",
  WRIST: "Wrist",
});

export const documentTypes = Object.freeze({
  CBA: "CBA",
  DRUG_POLICY: "Drug Policy",
  AGENT_REGULATION: "Agent Regulations",
});
