import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getTeams,
  getTeam,
  postTeam,
  putTeam,
  deleteTeam,
} from "../redux/teams";

export const fetchTeams = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/teams`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getTeams(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchTeam = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/team/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getTeam(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createTeam = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/teams`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postTeam(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateTeam = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/teams/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putTeam(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const removeTeam = (id) => async (dispatch, getState) => {
  try {
    await axiosHandler({
      url: `admin/teams/${id}`,
      method: "DELETE",
      state: getState(),
    });
    await dispatch(deleteTeam(id));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
