import React from "react";
import { Switch } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

const Switcher = ({ register, control, disabled }) => (
  <Controller
    render={({ field: { onChange, value } }) => (
      <Switch
        isChecked={value}
        size="lg"
        onChange={onChange}
        isDisabled={disabled}
        {...register}
      />
    )}
    control={control}
    name={register.name}
  />
);

export default Switcher;
