// Actions
const LOAD_ALL = "users/LOAD_ALL";
const LOAD_UNIQUE = "users/LOAD_UNIQUE";
const CREATE = "users/CREATE";
const UPDATE = "users/UPDATE";
const REMOVE_UNIQUE = "users/REMOVE_UNIQUE";
const REMOVE_ALL = "users/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        data: action.users.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.user.id]: action.user,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.user.id]: { ...state.data[action.user.id], ...action.user },
        },
      };
    case REMOVE_UNIQUE:
      return {
        ...state,
        data:
          Object.values(state.data || [])
            .filter((u) => u.id !== action.id)
            .reduce((a, b) => ((a[b?.id] = b), a), {}) || null,
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const getUsers = (users) => ({ type: LOAD_ALL, users });
export const getUser = (user) => ({ type: LOAD_UNIQUE, user });
export const postUser = (user) => ({ type: CREATE, user });
export const putUser = (user) => ({ type: UPDATE, user });
export const deleteUser = (id) => ({ type: REMOVE_UNIQUE, id });
export const deleteUsers = () => ({ type: REMOVE_ALL });
