import {
  ChakraProvider,
  Portal,
  useDisclosure,
  ColorModeScript,
} from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import routes from "routes.js";
import theme from "theme/theme.js";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import { useSelector } from "react-redux";
import useCurrentPath from "hooks/useCurrentPath";

export default function Dashboard(props) {
  const currentPath = useCurrentPath();

  const { path, isSearch } = useCurrentPath();
  const { onOpen } = useDisclosure();
  const [search, setSearch] = React.useState("");

  const isLogin = useSelector((state) => state.auth.accessToken);

  const protectedRoute = (component) => {
    const element = React.cloneElement(component, { search });
    return isLogin ? element : <Navigate to="/login" />;
  };

  const getActiveRoute = (routes) =>
    routes.find((r) => r.path === path)?.name || "Stratic Admin";

  const getRoutes = (routes) =>
    routes.map(({ path, component, loginRequired }, key) =>
      loginRequired ? (
        <Route
          exact
          path={path}
          element={protectedRoute(component)}
          key={key}
        />
      ) : (
        <Route exact path={path} element={component} key={key} />
      )
    );

  const getBreadCrumbs = (routes) => {
    const activeRoute = routes.find((r) => r.path === path);
    const crumbs = activeRoute.breadcrumbs?.map((r) => ({
      name: r,
      path: routes.find((r2) => r2.name === r)?.path,
    })) || [
      {
        name: activeRoute.name,
        path: activeRoute.path,
      },
    ];
    return crumbs;
  };

  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Sidebar
        routes={routes.filter((r) =>
          isLogin ? !r.hide && r.loginRequired : !r.hide && !r.loginRequired
        )}
        logoText={"Stratic"}
        display="none"
        sidebarVariant={"transparent"}
        {...props}
      />
      <MainPanel
        w={{
          base: "100%",
          xl: "calc(100% - 275px)",
        }}
      >
        <Portal>
          <AdminNavbar
            isSearch={isSearch}
            onOpen={onOpen}
            logoText={"Stratic"}
            brandText={getActiveRoute(routes)}
            breadcrumbs={getBreadCrumbs(routes)}
            secondary={false}
            fixed={true}
            onSearch={setSearch}
            {...props}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            <Routes>{getRoutes(routes)}</Routes>
          </PanelContainer>
        </PanelContent>
        <Footer />
      </MainPanel>
    </ChakraProvider>
  );
}
