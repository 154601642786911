// Actions
const LOAD_ALL = "events/LOAD_ALL";
const LOAD_UNIQUE = "events/LOAD_UNIQUE";
const CREATE = "events/CREATE";
const UPDATE = "events/UPDATE";
const REMOVE_UNIQUE = "events/REMOVE_UNIQUE";
const REMOVE_ALL = "events/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        ...state,
        data: action.events.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.event.id]: action.event,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.event.id]: {
            ...state.data[action.event.id],
            ...action.event,
          },
        },
      };
    case REMOVE_UNIQUE:
      return {
        ...state,
        data:
          Object.values(state.data || [])
            .filter((u) => u.id !== action.id)
            .reduce((a, b) => ((a[b?.id] = b), a), {}) || null,
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const getEvents = (events) => ({ type: LOAD_ALL, events });
export const getEvent = (event) => ({ type: LOAD_UNIQUE, event });
export const postEvent = (event) => ({ type: CREATE, event });
export const putEvent = (event) => ({ type: UPDATE, event });
export const deleteEvent = (id) => ({ type: REMOVE_UNIQUE, id });
export const deleteEvents = () => ({ type: REMOVE_ALL });
