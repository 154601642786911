import { useState, useEffect, useMemo } from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useReduxQuery, useReduxMutation } from "hooks/useReduxQuery";
import { updateUser } from "api/users";
import useSearchPlayer from "hooks/useSearchPlayer";
import { fetchTeams } from "api/teams";
import { fetchEvents } from "api/events";
import { fetchUser } from "api/users";
import { leagueTypes } from "lib/type";
import { useSuccessToast } from "hooks/useToastNotification";
import { useErrorToast } from "hooks/useToastNotification";
import { useDispatch } from "react-redux";
import { createUpload } from "api/upload";
import { userTypes } from "lib/type";
import { fetchUsers } from "api/users";

const useUser = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [userType, setUserType] = useState(userTypes.PLAYER);
  const [userLabel, setUserLabel] = useState(null);

  const {
    isSearching,
    searchHandler,
    errorSearch,
    updateData,
  } = useSearchPlayer();

  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const updatedHandler = () => setEdit(false);
  const updateMutation = useReduxMutation(updateUser, updatedHandler);

  useSuccessToast(updateMutation);
  useErrorToast(updateMutation);

  const { user } = useReduxQuery("User", () => fetchUser(userId), {
    selector: (state) => state.users.data?.[userId],
  });

  const { teams } = useReduxQuery("Teams", fetchTeams, {
    selector: (state) =>
      Object.values(state.teams.data || [])?.map((t) => ({
        value: t.id,
        label: `${t.city} ${t.name}`,
        data: t,
      })),
  });

  const { events } = useReduxQuery("Events", fetchEvents, {
    selector: (state) =>
      Object.values(state.events.data || [])?.map((e) => ({
        value: e.id,
        label: e.title,
      })),
  });

  const { users: agents } = useReduxQuery("Users", fetchUsers, {
    selector: (state) =>
      Object.values(state.users.data || [])
        .filter((u) => u.userType === userTypes.AGENT)
        .map(u => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`
        }))
  });

  const {
    handleSubmit,
    register,
    reset,
    setError,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const updateImageUpload = async (data) => {
    delete data?.profileUrl;
    if (data.image && data.image.path) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("File", data.image);
        const key = await dispatch(createUpload(formData, "user_picture"));
        data.pictureKey = key.files;
        setLoading(false);
        updateHandler(data);
      } catch (error) {
        setLoading(false);
        alert(error);
      }
    } else {
      if (!data.image) data.pictureKey = null;
      updateHandler(data);
    }
  };

  const updateHandler = async (data) => {
    if (data.password == "") delete data.password;

    const body = {
      id: userId,
      ...data,
      userType: data?.userType?.value,
      teamId: data.teamId?.value,
      status: data.status?.value,
      type: data.type?.value || playerTypes.NONE,
      eventsId: data?.eventsId?.map((e) => e.value),
      agents: data?.agents.map((a) => a.value)
    };

    if (body.nextPaycheck) {
      body.nextPaycheck = body?.nextPaycheck.toString().replace(/,/g, "");
    }

    if (body.userType === userTypes.EXECUTIVE) {
      body.executive = {
        scoutType: data?.scoutType,
        nextPaycheck: body?.nextPaycheck,
        yearsWithCurrentTeam: Number(data?.yearsWithCurrentTeam),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (body.userType === userTypes.COACH) {
      body.coach = {
        coachType: data?.coachType,
        nextPaycheck: body?.nextPaycheck,
        yearsWithCurrentTeam: Number(data?.yearsWithCurrentTeam),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (body.userType === userTypes.REPORTER) {
      body.reporter = {
        yearsWithCurrentTeam: Number(data?.yearsWithCurrentTeam),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (body.userType === userTypes.AGENT) {
      body.agent = {
        yearsWithCurrentCompany: Number(data?.yearsWithCurrentCompany),
        yearsActive: Number(data?.yearsActive),
      };
    }

    if (data.spotracId) {
      let position = data.contract.position;
      let contract = user.contract;
      let marketValue = user.marketValue;
      let teamId = data?.teamId?.value;
      const {
        marketValue: newMarketValue,
        contract: newContract,
        position: newPosition,
        teamId: newTeamId,
      } = await updateData(data, teams);
      position = newPosition;
      contract = { ...newContract, newSpotrac: true };
      marketValue = newMarketValue;
      teamId = newTeamId;

      body.teamId = teamId;
      body.status = data?.status?.value;
      body.position = position;
      body.contract = contract;
      body.marketValue = marketValue;
      body.eventsId = data?.eventsId
        ?.map((a) =>
          a.value === 0 ? events.shift() && events.map((u) => u.value) : a.value
        )
        .flat();
    }

    updateMutation.submit(body);
  };

  const teamsOptions = useMemo(
    () =>
      Object.values(leagueTypes).map((league) => ({
        label: league,
        options: teams
          ?.filter(
            (t) => t?.data?.league?.toLowerCase() === league?.toLowerCase()
          )
          .sort((a, b) => a.label.localeCompare(b.label)),
      })),
    [teams]
  );

  useEffect(() => {
    if (user) {
      const executive = user?.executive;
      const coach = user?.coach;
      const reporter = user?.reporter;
      const data = {
        ...coach,
        ...executive,
        ...reporter,
        ...user,
        teamId: {
          value: user.team?.id,
          label: `${user.team?.city} ${user.team?.name}`,
        },
        userType: {
          value: user?.userType,
          label: user?.userType,
        },
        status: {
          value: user.status,
          label: user.status,
        },
        type: {
          value: user.type,
          label: user.type,
        },
        eventsId: user.usersAssigned?.map((e) => ({
          value: e.id,
          label: e.title,
        })),
        agents: (user?.agents || []).map((u) => ({
          value: u.id,
          label: `${u.firstName} ${u.lastName}`
        })),
      };
      if (user?.pictureKey) {
        data.image = {
          name: user?.pictureKey?.split("/").shift().split("~").shift(),
          preview: user?.profileUrl,
        };
      }

      data.password = "";

      reset(data);

      setUserType(user.userType);

      if (user.userType !== userTypes.EXECUTIVE) {
        setUserLabel(user.userType?.toLowerCase());
      } else {
        setUserLabel("scout");
      }
    }
  }, [user]);

  useEffect(() => {
    if (errorSearch)
      setError("spotracId", { type: "custom", message: errorSearch });
    else clearErrors("spotracId");
  }, [errorSearch]);

  return {
    handleSubmit,
    register,
    control,
    errors,
    isEdit,
    setEdit,
    isLoading,
    updateHandler: updateImageUpload,
    isSearching,
    searchHandler,
    textColor,
    bgButton,
    teamsOptions,
    userId,
    userType,
    userLabel,
    updateMutation,
    errorSearch,
    events,
    watch,
    reset,
    agents
  };
};

export default useUser;
