import React, { useMemo, useEffect, Fragment } from "react";
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  useColorModeValue,
  Box,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTable, useSortBy, useGlobalFilter } from "react-table";

import { fetchContacts, removeContact } from "api/contacts";
import { useReduxQuery } from "hooks/useReduxQuery";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { CreatedAt, Action, Name } from "components/Tables/TablesTableRow";
import { Basic } from "components/Tables/TablesTableRow";

function ProfessionalServices({ search }) {
  const { contacts, loadingContacts } = useReduxQuery("Contacts", () =>
    fetchContacts({ type: "professionalService" })
  );
  const textColor = useColorModeValue("gray.700", "white");
  const sortingLeague = ["NFLPA", "NFL", "CFL", "UFL"];

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (contact) => [
          contact.firstName,
          contact.lastName,
          contact.role,
        ],
        Cell: ({ value: [firstName, lastName, role] }) => (
          <Name name={`${firstName} ${lastName}`} email={role} />
        ),
      },
      {
        Header: "Team",
        accessor: "team",
        sortType: "team",
        Cell: ({ value }) => (
          <Basic
            text={
              value?.league === "NFLPA" || !value
                ? "-"
                : `${value?.city} ${value?.name}`
            }
          />
        ),
      },
      {
        Header: "League",
        accessor: (contact) => [contact.team, contact.team?.league],
        Cell: ({ value: [team, league] }) => (
          <Basic text={league || team?.name} />
        ),
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <CreatedAt date={value} />,
      },
      {
        id: "action",
        accessor: (contact) => contact,
        disableSortBy: true,
        Cell: ({ value: { id, firstName, lastName } }) => (
          <Action
            keyObject="professional-services"
            id={id}
            title={`${firstName} ${lastName}`}
            remove={removeContact}
          />
        ),
      },
    ],
    []
  );

  const sortByLeague = (a, b) =>
    sortingLeague.indexOf(a?.team?.league) -
    sortingLeague.indexOf(b?.team?.league);

  const sortByTeam = (a, b) => {
    const teamA = a?.team?.name?.toLowerCase();
    const teamB = b?.team?.name?.toLowerCase();
    const cityA = a?.team?.city?.toLowerCase();
    const cityB = b?.team?.city?.toLowerCase();
    const fullTeamA = `${cityA}${teamA}`;
    const fullTeamB = `${cityB}${teamB}`;
    return fullTeamA < fullTeamB ? -1 : fullTeamA > fullTeamB ? 1 : 0;
  };

  const sortByName = (a, b) => {
    const nameA = `${a.firstName}${a.lastName}`.toLowerCase();
    const nameB = `${b.firstName}${b.lastName}`.toLowerCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  };

  const data = useMemo(() => Object.values(contacts || []), [contacts]).sort(
    (a, b) => sortByLeague(a, b) || sortByTeam(a, b) || sortByName(a, b)
  );

  const cleanSearch = (val) => val?.toLowerCase()?.trim()?.replace(/\s/g, "");

  const globalFilter = (rows, ids, query) => {
    return rows.filter((row) => {
      const rowValues = ids
        .filter((id) => ["Name", "team"].includes(id))
        .map((id) => row.values[id]);
      return rowValues.some((field) => {
        const name = cleanSearch(`${field?.[0]}${field?.[1]}`);
        const team = cleanSearch(field?.[3]?.name);
        const city = cleanSearch(field?.[3]?.city);
        const fullTeam = cleanSearch(`${team}${city}`);
        const cleanQuery = cleanSearch(query);
        const isMatch =
          team?.includes(cleanQuery) ||
          city?.includes(cleanQuery) ||
          fullTeam?.includes(cleanQuery) ||
          name?.includes(cleanQuery);

        return isMatch;
      });
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data, globalFilter }, useGlobalFilter, useSortBy);

  useEffect(() => {
    setGlobalFilter(search || "");
  }, [search, setGlobalFilter]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <Header textColor={textColor} />
        <CardBody>
          {loadingContacts && <Loader />}
          {!loadingContacts && (
            <Table variant="simple" color={textColor} {...getTableProps()}>
              <TableHeader headerGroups={headerGroups} />
              <Tbody {...getTableBodyProps()}>
                {rows.map((row, idx) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={idx}>
                      {row.cells.map((cell, index) => (
                        <Fragment key={index}>{cell.render("Cell")}</Fragment>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ProfessionalServices;

const Header = ({ textColor }) => {
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const title = "Professional Services List";

  return (
    <CardHeader>
      <Flex justify="space-between" align="center" minHeight="60px" w="100%">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title}
        </Text>
        <Button
          bg={bgButton}
          color="white"
          fontSize="xs"
          variant="no-hover"
          as={ReactRouterLink}
          to="/professional-services/create"
        >
          ADD NEW PROFESSIONAL SERVICE
        </Button>
      </Flex>
    </CardHeader>
  );
};

const Loader = () => (
  <Flex w="100%" justifyContent="center" py={80}>
    <Spinner thickness="5px" size="xl" />
  </Flex>
);

const TableHeader = ({ headerGroups }) => {
  return (
    <Thead>
      {headerGroups.map((headerGroup, index) => (
        <Tr
          {...headerGroup.getHeaderGroupProps()}
          my=".8rem"
          pl="0px"
          color="gray.400"
          key={index}
        >
          {headerGroup.headers.map((column, idx) => (
            <TableHeaderContent column={column} key={idx} index={idx} />
          ))}
        </Tr>
      ))}
    </Thead>
  );
};

const TableHeaderContent = ({ column, index }) => {
  return (
    <Th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      color="gray.400"
      ps={index === 0 ? "0px" : null}
    >
      <Flex
        alignItems="center"
        justifyContent={column.isNumeric ? "flex-end" : null}
      >
        <Box as="span">{column.render("Header")}</Box>
        <Box pl="4">
          {column.isSorted &&
            (column.isSortedDesc ? (
              <TriangleDownIcon aria-label="sorted descending" />
            ) : (
              <TriangleUpIcon aria-label="sorted ascending" />
            ))}
        </Box>
      </Flex>
    </Th>
  );
};
