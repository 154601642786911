import React, { useMemo, useEffect, Fragment } from "react";
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  useColorModeValue,
  Box,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTable, useSortBy, useGlobalFilter } from "react-table";

import { fetchUsers } from "api/users";
import { useReduxQuery } from "hooks/useReduxQuery";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { CreatedAt, Action, Name } from "components/Tables/TablesTableRow";
import { removeUser } from "api/users";

function Users({ search }) {
  const { users, loadingUsers } = useReduxQuery("Users", fetchUsers);
  const textColor = useColorModeValue("gray.700", "white");

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (user) => [
          user.firstName,
          user.lastName,
          user.email,
          user.profileUrl,
        ],
        Cell: ({ value: [firstName, lastName, email, profileUrl] }) => (
          <Name
            name={`${firstName} ${lastName}`}
            email={email}
            profileUrl={profileUrl}
          />
        ),
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <CreatedAt date={value} />,
      },
      {
        id: "action",
        accessor: (user) => user,
        disableSortBy: true,
        Cell: ({ value: { id, firstName, lastName } }) => (
          <Action
            keyObject="users"
            id={id}
            title={`${firstName} ${lastName}`}
            remove={removeUser}
          />
        ),
      },
    ],
    []
  );

  const data = useMemo(() => Object.values(users || []), [users]).sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy);

  useEffect(() => {
    setGlobalFilter(search || "");
  }, [search, setGlobalFilter]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <Header textColor={textColor} />
        <CardBody>
          {loadingUsers && <Loader />}
          {!loadingUsers && (
            <Table variant="simple" color={textColor} {...getTableProps()}>
              <TableHeader headerGroups={headerGroups} />
              <Tbody {...getTableBodyProps()}>
                {rows.map((row, idx) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={idx}>
                      {row.cells.map((cell, index) => (
                        <Fragment key={index}>{cell.render("Cell")}</Fragment>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Users;

const Header = ({ textColor }) => {
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const title = "Users List";

  return (
    <CardHeader>
      <Flex justify="space-between" align="center" minHeight="60px" w="100%">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title}
        </Text>
        <Button
          bg={bgButton}
          color="white"
          fontSize="xs"
          variant="no-hover"
          as={ReactRouterLink}
          to="/users/create"
        >
          ADD NEW USER
        </Button>
      </Flex>
    </CardHeader>
  );
};

const Loader = () => (
  <Flex w="100%" justifyContent="center" py={80}>
    <Spinner thickness="5px" size="xl" />
  </Flex>
);

const TableHeader = ({ headerGroups }) => {
  return (
    <Thead>
      {headerGroups.map((headerGroup, index) => (
        <Tr
          {...headerGroup.getHeaderGroupProps()}
          my=".8rem"
          pl="0px"
          color="gray.400"
          key={index}
        >
          {headerGroup.headers.map((column, idx) => (
            <TableHeaderContent column={column} key={idx} index={idx} />
          ))}
        </Tr>
      ))}
    </Thead>
  );
};

const TableHeaderContent = ({ column, index }) => {
  return (
    <Th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      color="gray.400"
      ps={index === 0 ? "0px" : null}
    >
      <Flex
        alignItems="center"
        justifyContent={column.isNumeric ? "flex-end" : null}
      >
        <Box as="span">{column.render("Header")}</Box>
        <Box pl="4">
          {column.isSorted &&
            (column.isSortedDesc ? (
              <TriangleDownIcon aria-label="sorted descending" />
            ) : (
              <TriangleUpIcon aria-label="sorted ascending" />
            ))}
        </Box>
      </Flex>
    </Th>
  );
};
