import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireNotAuth = () => {
  const token = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.admin.data);
  const location = useLocation();

  const redirectTo = !token || !user ? false : user ? "/users" : false;

  if (redirectTo)
    return <Navigate to={redirectTo} state={{ from: location }} replace />;

  return <Outlet />;
};

export default RequireNotAuth;
