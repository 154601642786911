// Actions
const LOAD_ALL = "teams/LOAD_ALL";
const LOAD_UNIQUE = "teams/LOAD_UNIQUE";
const CREATE = "teams/CREATE";
const UPDATE = "teams/UPDATE";
const REMOVE_UNIQUE = "teams/REMOVE_UNIQUE";
const REMOVE_ALL = "teams/REMOVE_ALL";

// Reducer
const initialState = {
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ALL:
      return {
        data: action.teams.reduce((a, b) => ((a[b.id] = b), a), {}),
      };
    case LOAD_UNIQUE:
    case CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.team.id]: action.team,
        },
      };
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.team.id]: { ...state.data[action.team.id], ...action.team },
        },
      };
    case REMOVE_UNIQUE:
      return {
        ...state,
        data:
          Object.values(state.data || [])
            .filter((u) => u.id !== action.id)
            .reduce((a, b) => ((a[b?.id] = b), a), {}) || null,
      };
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export const getTeams = (teams) => ({ type: LOAD_ALL, teams });
export const getTeam = (team) => ({ type: LOAD_UNIQUE, team });
export const postTeam = (team) => ({ type: CREATE, team });
export const putTeam = (team) => ({ type: UPDATE, team });
export const deleteTeam = (id) => ({ type: REMOVE_UNIQUE, id });
export const deleteTeams = () => ({ type: REMOVE_ALL });
