import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";

export const useSuccessToast = (mutation) => {
  const toast = useToast();
  useEffect(() => {
    if (mutation.loading === false && mutation.success === true)
      toast({
        title: "Success",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
  }, [mutation.loading]);
};

export const useErrorToast = (mutation) => {
  const toast = useToast();
  useEffect(() => {
    if (
      mutation.loading === false &&
      mutation.error &&
      mutation.success === false
    ) {
      const customError = checkError(mutation.error);
      toast({
        title: customError,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [mutation.loading]);
};
const checkError = (error) => {
  if (error == "bla") return "ewfwefwef";
  else return error;
};
