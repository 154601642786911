import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { API_URL } from "./constants";
dayjs.extend(utc);

export const axiosHandler = async ({
  url,
  params,
  method = "GET",
  data,
  state,
  headers,
  customBaseURL,
}) => {
  if (!url) throw Error("NO_URL_SPECIFIED");
  if (!method) throw Error("NO_METHOD_SPECIFIED");
  const baseURL = customBaseURL || API_URL;
  const options = {
    url,
    baseURL,
    method,
    params,
    data,
    headers,
    crossDomain: true,
  };
  if (state) {
    const { accessToken } = state.auth;
    if (!accessToken) throw Error("TOKEN_REQUIRED");
    options["headers"] = {
      ...options["headers"],
      Authorization: `Bearer ${accessToken}`,
    };
  }
  try {
    const res = await axios(options);
    return res.data;
  } catch (error) {
    throw Error(error?.response?.data?.error || error);
  }
};

export const appendParamsToUrl = (params) =>
  Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

export const wait = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const lowerFirstLetter = (string) => {
  return string?.charAt(0)?.toLowerCase() + string?.slice(1);
};
export const upperFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const formatDate = (val, format = "MMM DD, YYYY | HH:mm a") =>
  dayjs(val).utc().format(format);

export const truncate = (str, n = 100) =>
  str?.length > n ? str?.substr(0, n - 1) + "..." : str;

export const validateEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const numberWithCommas = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const mergeArrays = (firstArray, secondArray, yearKey) => {
  const result = firstArray.map((firstItem) => {
    const matchingItem = secondArray.find(
      (secondItem) => secondItem[yearKey] === firstItem[yearKey]
    );

    if (!matchingItem) return firstItem;

    const mergedItem = { ...firstItem };
    Object.keys(firstItem).forEach((key) => {
      if (
        key !== yearKey &&
        firstItem[key] === null &&
        matchingItem[key] !== null
      ) {
        mergedItem[key] = matchingItem[key];
      }
    });

    return mergedItem;
  });

  secondArray.forEach((secondItem) => {
    if (
      !result.find((resultItem) => resultItem[yearKey] === secondItem[yearKey])
    ) {
      result.push(secondItem);
    }
  });

  return result;
};
