import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";
import {
  getBreakdowns,
  getBreakdown,
  postBreakdown,
  putBreakdown,
  deleteBreakdown,
} from "../redux/breakdowns";

export const fetchBreakdowns = () => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/breakdowns`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getBreakdowns(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const fetchBreakdown = (id) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/breakdowns/${id}`,
      method: "GET",
      state: getState(),
    });
    await dispatch(getBreakdown(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createBreakdown = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/breakdowns`,
      method: "POST",
      data,
      state: getState(),
    });
    await dispatch(postBreakdown(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateBreakdown = (data) => async (dispatch, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/breakdowns/${data.id}`,
      method: "PUT",
      data,
      state: getState(),
    });
    await dispatch(putBreakdown(json?.data));
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
