import { axiosHandler } from "../lib/utils";
import * as Sentry from "@sentry/react";

export const createUpload = (data, type) => async (_, getState) => {
  try {
    const json = await axiosHandler({
      url: `admin/upload?s3Path=${type}`,
      method: "POST",
      data,
      state: getState(),
    });
    return json?.data;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
